<template>
  <ContentWrapper>
    <NoProducts
      v-if="!showList"
      @add="handleAdd"
    />

    <div v-if="showList" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_7_1_3_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_7_1_3_label_items_count') }}:</div>
            3
          </div>
          <div class="submenu-items">
            <Button size="nav" type="secondary" @click="showModalEditImportances = true">
              <IcoChartPie/>
              {{ $t('15_7_1_3_btn_edit_importances') }}
            </Button>
            <Button size="nav" type="primary">
              <IcoPlus/>
              {{ $t('15_7_1_3_btn_add_product_service') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalEditImportances"
      @closeModal="showModalEditImportances = false"
    >
      <ModalEditImportances @close="showModalEditImportances = false" />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAdd"
      @closeModal="showModalAdd = false"
    >
      <ProductsModalAdd @close="showModalAdd = false" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import NoProducts from "@/components/sources/components/products/NoProducts.vue";
import ProductCard from "@/components/sources/components/products/ProductCard.vue";
import Modal from "@/components/Modal.vue";
import ModalEditImportances from "@/components/sources/components/ModalEditImportances.vue";
import ProductsModalAdd from "@/components/sources/components/products/ProductsModalAdd.vue";
import ProductsModalDelete from "@/components/sources/components/products/ProductsModalDelete.vue";

export default {
  components: {
    ProductsModalDelete,
    ProductsModalAdd,
    ModalEditImportances,
    Modal,
    ProductCard,
    NoProducts,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  computed: {},
  data() {
    return {
      showList: false,
      showModalEditImportances: false,
      showModalAdd: false,
      showModalDelete: false,
    }
  },
  methods: {
    handleAdd() {
      this.showList = true;
      this.showModalAdd = true;
    }
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
