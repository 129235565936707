<template>
  <ContentWrapper>
    <div class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_3_2_main_title') }}</h1>

        <div class="boxed">
          <div class="box">

            <h3 class="sources_subtitle">{{ $t('15_3_2_title_brand_manual') }}</h3>

            <FormGroup :label="$t('15_3_2_label_communication_manual')" :required="false">
              <Resources
                :items="resources"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                transCreateNew="15_3_2_label_communication_manual_add"
                @updateResources="updateResources"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "s
              />
            </FormGroup>

            <Accordion :title="$t('15_3_2_accordion_title_brand_manual')" :description="$t('15_3_2_accordion_description_brand_manual')">
              <FormGroup :label="$t('15_3_2_label_claim')" :required="false">
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('15_3_2_placeholder_claim')"
                  v-model="claim"
                  @input="validationError.claim = false"
                  ref="contentName"
                />
              </FormGroup>

              <div class="column">
                <FormGroup :label="$t('15_3_2_label_vision')" :required="false">
              <textarea
                class="form-group__input large"
                :placeholder="$t('15_3_2_placeholder_vision')"
                v-model="vision"
                @input="validationError.vision = false"
                ref="contentName"
              ></textarea>
                </FormGroup>

                <FormGroup :label="$t('15_3_2_label_mission')" :required="false">
              <textarea
                class="form-group__input large"
                :placeholder="$t('15_3_2_placeholder_mission')"
                v-model="vision"
                @input="validationError.vision = false"
                ref="contentName"
              ></textarea>
                </FormGroup>

                <FormGroup :label="$t('15_3_2_label_values')" :required="false">
              <textarea
                class="form-group__input large"
                :placeholder="$t('15_3_2_placeholder_values')"
                v-model="mission"
                @input="validationError.mission = false"
                ref="contentName"
              ></textarea>
                </FormGroup>
              </div>

              <div class="sources-keyword-copy">
                <FormGroup
                  :focused="focusKeywords"
                  :label="$t('15_3_2_label_used_keywords')"
                  :required="false"
                >
                  <Keywords
                    v-model="keywords"
                    :placeholder="$t('15_3_2_placeholder_used_keywords')"
                    @save_changed_value="updateKeywords"
                    @focus="focusKeywords = true"
                    @blur="focusKeywords = false"
                  />
                </FormGroup>
                <ButtonCopy
                  title="15_3_2_title_keywords_copied_to_clipboard"
                  description="15_3_2_description_keywords_copied_to_clipboard"
                  :items="keywords"
                />
              </div>

              <div class="sources-keyword-copy">
                <FormGroup
                  :focused="focusHashtags"
                  :label="$t('15_3_2_label_used_hashtags')"
                  :required="false"
                >
                  <Keywords
                    v-model="hashtags"
                    :placeholder="$t('15_3_2_placeholder_used_hashtags')"
                    @save_changed_value="updateHashtags"
                    @focus="focusHashtags = true"
                    @blur="focusHashtags = false"
                  />
                </FormGroup>
                <ButtonCopy
                  title="15_3_2_title_hashtags_copied_to_clipboard"
                  description="15_3_2_description_hashtags_copied_to_clipboard"
                  :items="hashtags"
                />
              </div>

              <FormGroup :label="$t('15_3_2_label_other_resources')" :required="false">
                <Resources
                  :items="resources"
                  :disabled="role === 'READ_ONLY'"
                  :showError="resourcesIsOpenShowError"
                  transCreateNew="15_3_2_other_resources_add"
                  @updateResources="updateResources"
                  @opened="resourcesIsOpen = true"
                  @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                />
              </FormGroup>
            </Accordion>
          </div>
          <div class="box">
            <h3 class="sources_subtitle">{{ $t('15_3_2_title_visual_identity') }}</h3>

            <FormGroup :label="$t('15_3_2_label_identity_manual')" :required="false">
              <Resources
                :items="visualIdentity"
                :disabled="role === 'READ_ONLY'"
                :showError="visualIdentityIsOpenShowError"
                transCreateNew="15_3_2_label_identity_manual_add"
                @updateResources="updateVisualIdentity"
                @opened="visualIdentityIsOpen = true"
                @closed="
                      ;(visualIdentityIsOpen = false),
                        (visualIdentityIsOpenShowError = false)
                    "
              />
            </FormGroup>

            <Accordion :title="$t('15_3_2_accordion_title_visual_identity')" :description="$t('15_3_2_accordion_description_visual_identity')">

              <div class="sources-keyword-copy">
                <FormGroup
                  :focused="focusKeywords"
                  :label="$t('15_3_2_label_logo')"
                  :required="false"
                >
                  <input
                    class="form-group__input"
                    type="text"
                    :placeholder="$t('15_3_2_placeholder_logo')"
                    v-model="logo"
                    @input="validationError.logo = false"
                    ref="contentName"
                  />
                </FormGroup>
                <ButtonCopy
                  title="15_3_2_title_logo_copied_to_clipboard"
                  description="15_3_2_description_logo_copied_to_clipboard"
                  :items="logo"
                />
                <ButtonOpenLink
                  :link="logo"
                />
              </div>

              <div class="sources-keyword-copy">
                <FormGroup
                  :focused="focusKeywords"
                  :label="$t('15_3_2_label_font')"
                  :required="false"
                >
                  <input
                    class="form-group__input"
                    type="text"
                    :placeholder="$t('15_3_2_placeholder_font')"
                    v-model="font"
                    @input="validationError.font = false"
                    ref="contentName"
                  />
                </FormGroup>
                <ButtonCopy
                  title="15_3_2_title_font_copied_to_clipboard"
                  description="15_3_2_description_font_copied_to_clipboard"
                  :items="font"
                />
                <ButtonOpenLink
                  :link="font"
                />
              </div>

              <ColorsPicker />

              <FormGroup :label="$t('15_3_2_label_visual_identity_other_resources')" :required="false">
                <Resources
                  :items="visualIdentityOthers"
                  :disabled="role === 'READ_ONLY'"
                  :showError="visualIdentityOthersIsOpenShowError"
                  transCreateNew="15_3_2_label_visual_identity_other_resources_add"
                  @updateResources="updateVisualIdentity"
                  @opened="visualIdentityOthersIsOpen = true"
                  @closed="
                      ;(visualIdentityOthersIsOpen = false),
                        (visualIdentityOthersIsOpenShowError = false)
                    "
                />
              </FormGroup>
            </Accordion>
          </div>
          <div class="box">
            <Button type="primary">{{ $t('15_3_2_btn_save_changes') }}</Button>
          </div>
        </div>

      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import Resources from "@/components/design/Resources.vue";
import FormGroup from "@/components/FormGroup.vue";
import IcoCarretUp from "@/components/svg/IcoCarretUp.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Button from "@/components/button/Button.vue";
import ColorsPicker from "@/components/colorsPicker/ColorsPicker.vue";
import Keywords from "@/components/Keywords.vue";
import ButtonCopy from "@/components/buttonCopy/ButtonAdd.vue";
import ButtonOpenLink from "@/components/buttonOpenLink/ButtonOpenLink.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";

export default {
  components: {
    ContentWrapper,
    ButtonOpenLink,
    ButtonCopy,
    Keywords,
    ColorsPicker,
    Button,
    Accordion,
    IcoCarretUp,
    FormGroup,
    Resources

  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  data() {
    return {
      validationError: {
        claim: false,
        vision: '',
        mission: '',
        values: '',
        logo: '',
        font: '',
      },

      claim: '',
      vision: '',
      mission: '',
      values: '',

      logo: '',
      font: '',

      resourcesIsOpen: false,
      visualIdentityIsOpen: false,
      visualIdentityOthersIsOpen: false,

      resourcesIsOpenShowError: false,
      visualIdentityIsOpenShowError: false,
      visualIdentityOthersIsOpenShowError: false,

      resources: [],
      visualIdentity: [],
      visualIdentityOthers: [],

      keywords: [],
      focusKeywords: false,

      hashtags: [],
      focusHashtags: false,
    }
  },
  methods: {
    updateResources() {

    },
    updateVisualIdentity() {

    },
    updateKeywords(items) {
      this.keywords = items
    },
    updateHashtags(items) {
      this.hashtags = items
    },
  }
}
</script>
