<template>
  <div class="source-label" :class="{'is-empty': ico === ''}">
    <div class="source-label-ico">
      <div class="source-label-stdc-ico-dot"></div>
    </div>
    <div
      class="source-label-text"
      :style="{'color': color}"
    >
      {{ title }} (2)
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  computed: {

  },
  props: {
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'black',
    },
    ico: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
.source-label {
  padding: 3px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  border: 1px solid #D1D4D4;
  gap: 6px;
}

.source-label.is-empty .source-label-text {
  padding-left: 4px;
}

.source-label-ico {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.source-label-stdc-ico-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #17252A;
}

.source-label-text {
  padding-right: 4px;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
}
</style>
