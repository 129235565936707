<template>
  <div>
    <div class="sticky-header">
      <Menu />
    </div>

    <div
      v-if="!billingOverdue"
      class="container container--top"
      :class="{
        'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <GlobalNotification />

      <h1 v-if="companies.length > 0" class="main-title">{{ company.name }}</h1>

      <template v-if="companies.length > 0">
        <div class="cols">
          <div class="col">
            <div class="col__inner col__inner--mh" ref="col_inner">
              <h2 class="sub-title">{{ $t('6_3_subtitle_info') }}</h2>

              <div class="company-billing-date">
                <p>
                  {{ $t('6_3_company_active') }}
                  <strong
                    >{{ $t('6_3_company_active_until') }}
                    {{ company.billingDate | moment('D. M. YYYY') }}.</strong
                  >
                  <MailToSupport
                    :title="$t('6_3_contact_us')"
                    :subject="$t('6_3_company_billing_info')"
                    :body="mailToBody"
                  />
                </p>
              </div>

              <form action="" @submit.prevent="saveCompanyInformation()">
                <div class="line">
                  <FormGroup
                    :label="$t('6_3_label_name')"
                    :required="true"
                    :validationError="invalidName"
                    :focused="focusName"
                  >
                    <input
                      class="form-group__input"
                      type="text"
                      :placeholder="$t('6_3_placeholder_name')"
                      v-model="name"
                      @input="invalidName = false"
                      @focus="focusName = role !== 'READ_ONLY'"
                      @blur="focusName = false"
                      :maxlength="length.companyName"
                      :readonly="role === 'READ_ONLY'"
                    />
                  </FormGroup>
                </div>

                <div class="line">
                  <FormGroup
                    :label="$t('6_3_label_description')"
                    :required="false"
                    :validationError="invalidDescription"
                    :focused="focusDescription"
                  >
                    <textarea
                      class="form-group__input"
                      type="text"
                      :placeholder="$t('6_3_placeholder_description')"
                      style="min-height: 73px"
                      v-model="description"
                      @input="invalidDescription = false"
                      @focus="focusDescription = role !== 'READ_ONLY'"
                      @blur="focusDescription = false"
                      @keydown="getAvailableHeight"
                      :maxlength="length.companyDescription"
                      :readonly="role === 'READ_ONLY'"
                    ></textarea>
                  </FormGroup>
                </div>

                <div class="line">
                  <div
                    class="timezone"
                    :class="{
                      'is-invalid': invalidTimezone,
                      'has-focus': focusTimezone,
                    }"
                  >
                    <div class="timezone__label">
                      {{ $t('6_3_label_timezone') }}
                      <span class="form-group__required">*</span>
                    </div>
                    <div class="timezone__placeholder" v-if="timezone === ''">
                      {{ $t('6_3_placeholder_timezone') }}
                    </div>
                    <v-select
                      :options="timezones"
                      v-model="timezone"
                      :clearable="false"
                      :reduce="(timezone) => timezone.value"
                      :disabled="role === 'READ_ONLY'"
                      @input="timezoneUpdated()"
                      @open="focusTimezone = true"
                      @close="focusTimezone = false"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><IcoCarretDownBlack /></span>
                      </template>
                      <span
                        slot="no-options"
                        @click="$refs.select.open = false"
                      >
                        {{ $t('8_5_select_no_result') }}
                      </span>
                    </v-select>
                  </div>
                </div>

                <div v-if="role !== 'READ_ONLY'" class="line">
                  <CompanyLogo :title="$t('6_3_image_default_name')" />
                </div>

                <div class="line" v-if="role !== 'READ_ONLY'">
                  <Button
                    style="width: 100%"
                    :disabled="disabledEditCompany"
                    :type="'primary'"
                    >
                    {{ $t('6_3_btn_save') }}
                  </Button>
                </div>
              </form>
            </div>

            <div v-if="role !== 'READ_ONLY'" class="col__inner col__inner--top">
              <h2>{{ $t('6_3_invite_title') }}</h2>
              <p>
                {{ $t('6_3_invite_description') }}
                <span class="medium-info-ico"
                  ><IcoInfo v-tooltip="$t('6_3_invite_tooltip')"
                /></span>
              </p>

              <JoinEmail @done="handleInvitesSended" />
            </div>
          </div>

          <div v-if="role !== 'READ_ONLY'" class="col">
            <div class="col__inner col__inner--mh col__inner--bottom-padding">
              <template v-if="role !== 'READ_ONLY'">
                <div class="col__title">
                  <h2>{{ $t('6_3_subtitle_team') }}</h2>

                  <div>
                    <span>{{ $t('6_3_roles_and_permissions') }}</span>
                    <IcoInfo v-tooltip="$t('6_3_tooltip_team')" />
                  </div>
                </div>

                <TabSwitcher
                  v-model="userTab"
                  :items="[
                    { name: $t('6_3_tab_title_members'), value: 'members' },
                    { name: $t('6_3_tab_title_invited'), value: 'invited', count: invitesCount },
                  ]"
                />

                <div v-if="userTab === 'members'">
                  <div class="line line--short">
                    <Search color="light" v-model="searchTeam" />
                  </div>
                  <div>
                    <Person
                      v-for="(person, index) in searchTeamList"
                      :key="index"
                      :person="person"
                      @delete="deletePerson"
                      @updateRole="updateRole"
                    />

                    <div class="no-results" v-if="searchTeamList.length === 0">
                      <p>{{ $t('6_3_no_members') }}</p>
                    </div>
                  </div>
                </div>

                <div v-if="userTab === 'invited'">
                  <div class="line line--short">
                    <Search color="light" v-model="searchTeam" />
                  </div>
                  <div>
                    <PersonInvitation
                      v-for="(person, index) in searchInviteList"
                      :key="'invite-' + index"
                      :person="person"
                      @delete="deleteInvite"
                      @resend="resendInvite"
                    />

                    <div
                      class="no-results"
                      v-if="searchInviteList.length === 0"
                    >
                      <p>{{ $t('6_3_no_invitations') }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div v-if="role !== 'READ_ONLY'" class="cols cols--nostrech">
          <div class="col col--full col--center">
            <Button @click="showModalDeleteCompany = true">
              <IcoBin /> {{ $t('6_3_btn_delete') }}
            </Button>
          </div>
        </div>
      </template>
    </div>

    <ExpiredCompany v-else :company="company" />

    <ModalDeleteCompany
      v-if="showModalDeleteCompany && role !== 'READ_ONLY'"
      :company="company"
      @closeModal="closeModalDeleteCompany"
    />
  </div>
</template>

<script>
import textLength from '@/constants/TextLengthConstant.js'
import { DateService } from '@/services/DateService.js'

import vuescroll from 'vuescroll'

import COMPANY from '@/graphql/Company.gql'
import COMPANY_UPDATE from '@/graphql/CompanyUpdate.gql'
import COMPANY_LEAVE from '@/graphql/CompanyLeave.gql'
import INVITE_DELETE from '@/graphql/InviteDelete.gql'
import INVITE_RESEND from '@/graphql/InviteResend.gql'
import ROLE_UPDATE from '@/graphql/RoleUpdate.gql'

import Link from '@/components/design/Link.vue'
import Menu from '@/components/Menu.vue'
import Search from '@/components/Search.vue'
import Person from '@/components/Person.vue'
import PersonInvitation from '@/components/PersonInvitation.vue'

import FormGroup from '@/components/FormGroup.vue'
import Button from '@/components/button/Button.vue'
import LinkedAccounts from '@/components/LinkedAccounts.vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
import ShowError from '@/components/ShowError.vue'

import IcoBin from '@/components/svg/IcoBin.vue'
import IcoDuplicate from '../components/svg/IcoDuplicate.vue'
import JoinEmail from '../components/JoinEmail.vue'
import IcoCarretDownBlack from '@/components/svg/IcoCarretDownBlack.vue'
import IcoInfo from '@/components/svg/small/IcoInfo.vue'
import IcoInfoMedium from '@/components/svg/IcoInfo.vue'
import InactiveCompany from '@/components/svg/InactiveCompany.vue'

import ModalDeleteCompany from '@/components/ModalDeleteCompany.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import MailToSupport from '@/components/MailToSupport.vue'
import ExpiredCompany from '@/components/ExpiredCompany.vue'
import TabSwitcher from '@/components/TabSwitcher.vue'

export default {
  components: {
    vuescroll,
    Menu,
    Search,
    Person,
    PersonInvitation,
    FormGroup,
    LinkedAccounts,
    CompanyLogo,
    ShowError,
    Link,
    Button,
    IcoBin,
    IcoDuplicate,
    JoinEmail,
    ModalDeleteCompany,
    IcoCarretDownBlack,
    IcoInfo,
    IcoInfoMedium,
    GlobalNotification,
    MailToSupport,
    InactiveCompany,
    ExpiredCompany,
    TabSwitcher,
  },
  apollo: {
    companies: {
      query: COMPANY,
      variables() {
        return {
          id: this.companyID,
        }
      },
      skip() {
        return !this.companyID
      },
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    length() {
      return textLength
    },
    timezones() {
      return DateService.getListOfAvailableTimezones()
    },
    company() {
      if (this.companies.length > 0) {
        this.name = this.companies[0].name
        this.description = this.companies[0].description
        this.timezone = this.companies[0].zoneId
        this.billingOverdue = this.companies[0].billingOverdue

        return this.companies[0]
      }
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    companyID() {
      if (!this.$route.params.id) {
        if (this.selectedCompany) {
          return this.selectedCompany.id
        }
      } else {
        return this.$route.params.id
      }
    },
    searchTeamList() {
      if (this.companies.length === 0) return []

      if (this.searchTeam === '') {
        return this.company.users
      } else {
        return this.company.users.filter((item) => {
          return (
            item.name.toLowerCase().indexOf(this.searchTeam.toLowerCase()) !==
            -1
          )
        })
      }
    },
    invitesCount() {
      if (this.companies.length === 0) return 0

      if (this.company && this.company.invites) {
        return this.company.invites.length
      }

      return 0
    },
    searchInviteList() {
      if (this.companies.length === 0) return []

      if (this.searchTeam === '') {
        return this.company.invites
      } else {
        return this.company.invites.filter((item) => {
          return (
            item.email.toLowerCase().indexOf(this.searchTeam.toLowerCase()) !==
            -1
          )
        })
      }
    },
    mailToBody() {
      let _content = ''
      const content_cs =
        'Společnost: {companyName}, {companyId} \nZašlete mi fakturu na předplatné: Sólo Dino / Triasový Tým / Jurská Smečka / Obsahový T-Rex \nMám zájem o období: ROČNÍ / MĚSÍČNÍ \n\nSlevový kód:\nFakturační údaje: \n- Název firmy \n- Sídlo (ulice, město, psč, země) \n- IČO \n- DIČ'
      const content_en = `Company: {companyName}, {companyId} \nSend me an invoice for subscription: Solo Dino / Triassic Team / Jurassic Crew / Content T-Rex \nI am interested in this period: YEARLY / MONTHLY \n\nPromo code: \nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)`

      _content = content_cs

      if (this.$i18n.locale === 'en') {
        _content = content_en
      }

      _content = _content
        .replace('{companyName}', this.selectedCompany.name)
        .replace('{companyId}', this.selectedCompany.id)

      return encodeURIComponent(_content)
    },
  },
  data() {
    return {
      billingOverdue: false,
      prevHeight: 0,
      availableHeight: '344px',
      focusName: false,
      focusDescription: false,
      focusTimezone: false,

      companies: [],
      invites: [],
      showModalDeleteAccount: false,
      showModalForgotPassword: false,
      showModalDeleteCompany: false,
      disabledEditCompany: true,
      disabledInviteTeamMembers: true,
      errorEditCompany: [],
      name: '',
      description: '',
      timezone: 'Europe/Prague',
      searchTeam: '',
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        },
      },
      invalidName: false,
      invalidDescription: false,
      invalidTimezone: false,
      userTab: 'members',
    }
  },
  methods: {
    initPage() {
      this.$apollo.queries.companies.refetch()
    },
    handleInvitesSended() {
      this.$apollo.queries.companies.refetch()
    },
    getAvailableHeight() {
      let height = 100 // 344

      this.$nextTick(() => {
        height = this.$refs.col_inner.clientHeight
        height -= 196
        this.availableHeight = height + 'px'
        this.$refs['vs'].refresh()
      })
    },
    updateRole(role, person) {
      const currentUsers = [...this.company.users]
      const usersAfterDelete = currentUsers.filter(
        (item) => item.id !== person.id && item.role === 'ADMIN'
      )

      if (usersAfterDelete.length === 0) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_user_cannot_update_role'),
          text: this.$t(
            '6_3_2_toaster_error_description_user_cannot_update_role'
          ),
          type: 'error',
          duration: 5000,
        })

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: ROLE_UPDATE,
            variables: {
              companyId: this.selectedCompany.id,
              userId: person.id,
              role: role,
            },
            update: (store, data) => {
              /* this.$notify({
                title: this.$t('6_3_2_toaster_success_title_role_updated'),
                text: this.$t('6_3_2_toaster_success_description_role_updated'),
                type: 'success',
                duration: 5000
            }); */
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_role_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_role_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    timezoneUpdated() {
      this.invalidTimezone = false
      this.disabledEditCompany = false
    },
    deletePerson(person) {
      const currentUsers = [...this.company.users]
      const usersAfterDelete = currentUsers.filter(
        (item) => item.id !== person.id
      )

      if (usersAfterDelete.length === 0) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_user_cannot_delete'),
          text: this.$t('6_3_2_toaster_error_description_user_cannot_delete'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: COMPANY_LEAVE,
            variables: {
              id: this.company.id,
              userId: person.id,
            },
            update: (store, { data: { updateCompany } }) => {
              this.$notify({
                title: this.$t('6_3_1_toaster_success_title_user_deleted'),
                text: this.$t('6_3_1_toaster_success_description_user_deleted'),
                type: 'success',
                duration: 5000,
              })

              this.$apollo.queries.companies.refetch()

              if (person.id === this.me.id) {
                window.location = '/calendar'
              }
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_user_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_user_delete'),
          text: this.$t('6_3_2_toaster_error_description_user_delete'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    closeModalDeleteCompany() {
      this.showModalDeleteCompany = false
    },
    async setCompanyIdToClipboard() {
      await navigator.clipboard.writeText(this.companyID)

      this.$notify({
        title: this.$t('6_3_toaster_title_copied_to_clipboard'),
        text: this.$t('6_3_toaster_description_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })
    },
    saveCompanyInformation() {
      this.invalidName = false
      this.invalidDescription = false
      this.invalidTimezone = false

      if (this.name === '' || this.name.length < 3 || this.name.length > 50) {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_name'),
          text: this.$t('6_3_toaster_error_description_invalid_name'),
          type: 'error',
          duration: 5000,
        })

        this.invalidName = true
        return
      }

      if (this.description && this.description.length > 100) {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_description'),
          text: this.$t('6_3_toaster_error_description_invalid_description'),
          type: 'error',
          duration: 5000,
        })

        this.invalidDescription = true
        return
      }

      if (this.timezone === '') {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_timezone'),
          text: this.$t('6_3_toaster_error_description_invalid_timezone'),
          type: 'error',
          duration: 5000,
        })

        this.invalidTimezone = true
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: COMPANY_UPDATE,
            variables: {
              id: this.company.id,
              name: this.name,
              description: this.description,
              zoneId: this.timezone,
            },
            update: (store, { data: { updateCompany } }) => {
              this.$notify({
                title: this.$t('6_3_1_toaster_success_title_company_updated'),
                text: this.$t(
                  '6_3_1_toaster_success_description_company_updated'
                ),
                type: 'success',
                duration: 5000,
              })

              const cached = store.readQuery({
                query: COMPANY,
                variables: {
                  id: this.companyID,
                },
              })

              cached.companies[0].name = this.name
              cached.companies[0].description = this.description

              store.writeQuery({
                query: COMPANY,
                data: cached,
                variables: {
                  id: this.companyID,
                },
              })
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_user_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_user_delete'),
          text: this.$t('6_3_2_toaster_error_description_user_delete'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    deleteInvite(invite) {
      try {
        this.$apollo
          .mutate({
            mutation: INVITE_DELETE,
            variables: {
              id: invite.id,
            },
            update: (store, { data: { data } }) => {
              this.$notify({
                title: this.$t('6_3_2_toaster_success_title_invite_deleted'),
                text: this.$t(
                  '6_3_2_toaster_success_description_invite_deleted'
                ),
                type: 'success',
                duration: 5000,
              })

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_invite_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_invite_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    resendInvite(invite) {
      try {
        this.$apollo
          .mutate({
            mutation: INVITE_RESEND,
            variables: {
              id: invite.id,
            },
            update: (store, { data: { data } }) => {
              this.$notify({
                title: this.$t('6_3_2_toaster_success_title_invite_resend'),
                text: this.$t(
                  '6_3_2_toaster_success_description_invite_resend'
                ),
                type: 'success',
                duration: 5000,
              })

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_invite_resend'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_invite_resend'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
  watch: {
    name() {
      this.disabledEditCompany = this.name === this.company.name
    },
    description() {
      this.disabledEditCompany = this.description === this.company.description
    },
    company() {
      if (this.company.id !== this.selectedCompany.id) {
        this.$store.commit('setCompany', this.company)
      }
    },
    '$route.params': 'initPage',
  },
}
</script>

<style scoped>
.company-billing-date {
  margin-top: 14px;
}

.no-results {
  padding: 20px;
}

.no-results p {
  text-align: center;
}
</style>

<style>
.medium-info-ico {
  position: relative;
  top: 2px;
  left: 4px;
}

.medium-info-ico svg {
  width: 14px;
  height: 14px;
}
</style>
