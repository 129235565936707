<template>
  <div class="person-role" :class="{'show': showControl, 'readonly': readonly}">
    <div class="">
      <VDropdown @hide="closeData" :eagerMount="autoHide" :auto-hide="autoHide" :triggers="[]" :shown="showControl">
        <div @click="openControl" :class="selectedRole.id" class="person-role__selected"><span>{{ selectedRole.name }}</span> <IcoCarretDown v-if="!readonly" /></div>
        <template #popper>
          <div class="person-role__data">
            <ul>
              <li
                @click="updateRole(item.id)"
                v-for="(item, index) in roles"
                :key="index"
                :class="item.id"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </template>
      </VDropdown>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import IcoCarretDown from "@/components/svg/IcoCarretDown.vue";

export default {
  components: {
      IcoCarretDown
  },
  directives: {
    ClickOutside,
  },
  props: {
    role: {
      type: String,
      require: true,
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    roles() {
      // return ["ADMIN", "APPROVER"];
      return [
        {
          name: this.$t('role_admin'),
          id: 'ADMIN'
        },
        {
          name: this.$t('role_approver'),
          id: 'APPROVER'
        },
        {
          name: this.$t('role_owner'),
          id: 'OWNER'
        },
        {
          name: this.$t('role_editor'),
          id: 'EDITOR'
        },
      ]
    },
  },
  created() {
      this.selectedRole = {
        name: this.$t('role_' + this.role.toLowerCase()),
        id: this.role
      };
  },
  data() {
    return {
      autoHide: true,
      showControl: false,
      canBeClosed: false,
      selectedRole: ''
    };
  },
  methods: {
    openControl() {
      if (this.readonly) {
        this.showControl = false
        return;
      }

      if(this.showControl === true) {
        this.showControl = false
        setTimeout(() => this.showControl = true, 100);
      } else {
        this.showControl = true;
      }

      this.autoHide = false; setTimeout(() => this.autoHide = true, 300);
    },
    updateRole(role) {
      this.canBeClosed = true;
      this.showControl = false;

      this.selectedRole = {
        name: this.$t('role_' + role.toLowerCase()),
        id: role
      };

      this.$emit("updateRole", role)
      this.hideControl();
    },
    hideControl() {
      this.showControl = false;
    },
    closeData() {
      this.showControl = false;
    }
  },
  watch: {
    role() {
      this.selectedRole = {
        name: this.$t('role_' + this.role.toLowerCase()),
        id: this.role
      };
    }
  }
};
</script>

<style scoped>

.person-role .person-role__selected svg{
    transform: rotate(0);
    transition-duration: 0.2s;
    transition-property: transform;
}
.person-role.show .person-role__selected svg{
    transform: rotate(180deg);
    transition-duration: 0.2s;
    transition-property: transform;
}

.person-role__selected {
  width: 115px;
  height: 24px;

  overflow: hidden;
  border-radius: 4px;
  text-align: center;
  background: #e7e9e9;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  text-transform: uppercase;
  color: #888f91;
  mix-blend-mode: normal;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
}

.person-role.readonly .person-role__selected {
  cursor: default;
}

.person-role__selected span{
    position: relative;
    top: 1px;
}

.person-role__selected.ADMIN{
    font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  /* identical to box height */
  text-transform: uppercase;
  color: #23c2a7;

  mix-blend-mode: normal;
  background: #defef8;
  border-radius: 4px;
}

.person-role__selected.ADMIN{
  color: #23c2a7;
}

.person-role__selected.ADMIN{
  color: #23c2a7;
}

.person-role__data {
  width: 110px;
}

.person-role__data ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 4px;
  width: 100%;
}

.person-role__data ul li {
  width: 100%;
  display: block;
  height: 24px;
  line-height: 24px;

  overflow: hidden;
  border-radius: 4px;
  text-align: center;
  background: #e7e9e9;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 26px;
  text-transform: uppercase;
  color: #888f91;
  mix-blend-mode: normal;
  cursor: pointer;
  margin-top: 4px;
}

.person-role__data ul li.ADMIN {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  /* identical to box height */
  text-transform: uppercase;
  color: #23c2a7;

  /* 100 Dark Green */
  color: #23c2a7;

  mix-blend-mode: normal;
  background: #defef8;
  border-radius: 4px;
}

.person-role__data ul li:first-child {
  margin-top: 0;
}
</style>


<style>
    .person-role__selected svg path{
        stroke: #888F91;
    }

    .person-role__selected.ADMIN svg path{
        stroke: #23C2A7;
    }
</style>
