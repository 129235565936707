<template>
  <div class="sources">
    <div class="sticky-header">
      <Menu />
    </div>

    <div>Company sources</div>

    <SourcesSidebar />

    <router-view />

  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import SourcesSidebar from "@/components/sources/SourcesSidebar.vue";

export default {
  components: {
    SourcesSidebar,
    Menu,
  },
  computed: {

  },
  data() {
    return {

    }
  },
  methods: {

  },
  watch: {

  },
}
</script>


