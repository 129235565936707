<template>
  <ContentWrapper :disable-top-offset="false">
    <SlickMenu :title="stdc.name">
      <Button :disabled="savingStdc" @click="handleUpdateStdc" size="nav" type="primary">
        {{ $t('save') }}
      </Button>

      <Button @click="handleEditBasic" size="nav" type="secondary">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83301 6.33343H4.99967C4.55765 6.33343 4.13372 6.50903 3.82116 6.82159C3.5086 7.13415 3.33301 7.55807 3.33301 8.0001V15.5001C3.33301 15.9421 3.5086 16.3661 3.82116 16.6786C4.13372 16.9912 4.55765 17.1668 4.99967 17.1668H12.4997C12.9417 17.1668 13.3656 16.9912 13.6782 16.6786C13.9907 16.3661 14.1663 15.9421 14.1663 15.5001V14.6668M13.333 4.66677L15.833 7.16677M16.9872 5.98759C17.3154 5.65938 17.4998 5.21424 17.4998 4.75009C17.4998 4.28594 17.3154 3.84079 16.9872 3.51259C16.659 3.18438 16.2138 3 15.7497 3C15.2855 3 14.8404 3.18438 14.5122 3.51259L7.49967 10.5001V13.0001H9.99967L16.9872 5.98759Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_8_2_1_btn_edit') }}
      </Button>

      <Button @click="showModalEditImportances = true" size="nav" type="secondary">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.33359 3.16722C7.03001 3.46942 5.83102 4.11489 4.86099 5.03667C3.89095 5.95845 3.18521 7.12297 2.81694 8.40945C2.44868 9.69593 2.43132 11.0575 2.76666 12.353C3.102 13.6484 3.77783 14.8306 4.72404 15.7768C5.67026 16.723 6.8524 17.3988 8.14785 17.7341C9.4433 18.0695 10.8049 18.0521 12.0914 17.6839C13.3778 17.3156 14.5424 16.6099 15.4641 15.6398C16.3859 14.6698 17.0314 13.4708 17.3336 12.1672C17.3336 11.9462 17.2458 11.7342 17.0895 11.578C16.9332 11.4217 16.7213 11.3339 16.5003 11.3339H10.8336C10.3916 11.3339 9.96764 11.1583 9.65508 10.8457C9.34252 10.5332 9.16692 10.1092 9.16692 9.66722V3.83389C9.15657 3.73516 9.12672 3.63947 9.0791 3.55236C9.03148 3.46526 8.96705 3.38847 8.88953 3.32646C8.81201 3.26444 8.72296 3.21844 8.62752 3.1911C8.53209 3.16377 8.43218 3.15565 8.33359 3.16722Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.5003 3.41724C13.5584 3.78981 14.5194 4.39499 15.3126 5.1882C16.1058 5.98142 16.711 6.94247 17.0836 8.00057H13.3336C13.1126 8.00057 12.9006 7.91277 12.7443 7.75649C12.5881 7.60021 12.5003 7.38825 12.5003 7.16724V3.41724Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_8_2_1_btn_edit_importances') }}
      </Button>

      <!--
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_8_2_1_btn_print') }}
      </Button>
      -->

      <Button @click="showModalDelete = true" size="nav" type="delete">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_8_2_1_btn_delete') }}
      </Button>
    </SlickMenu>

    <div class="stdc-detail-lists">
      <div class="stdc-detail-list">
        <div class="stdc-detail-list-title">
          {{ $t('15_8_2_1_label_personas') }}:
        </div>
        <div class="stdc-detail-list-labels">
          <Label
            v-for="(persona, index) in selectedPersonas"
            :key="`persona-${index}`"
            :title="persona.name"
          />
        </div>
      </div>

      <div class="stdc-detail-list">
        <div class="stdc-detail-list-title">
          {{ $t('15_8_2_1_label_products_and_services') }}:
        </div>
        <div class="stdc-detail-list-labels">
          <Label
            v-for="(product, index) in selectedProducts"
            :key="`product-${index}`"
            :title="product.name"
          />
        </div>
      </div>
    </div>

    <div class="stdc-detail-description">TODO add missing description</div>

    <div class="stdc-detail-cards">
      <StdcBox
        v-if="see"
        title="SEE"
        color="#17252A"
        :show-trigger="true"
        :triggerTitle="$t('15_8_2_1_see_trigger_title')"
        :phase="see"
        :channels="channels"
        @editContent="editContent"
        @editChannels="editChannels"
      />

      <StdcBox
        v-if="think"
        title="THINK"
        color="#9795FF"
        :show-trigger="true"
        :triggerTitle="$t('15_8_2_1_think_trigger_title')"
        :phase="think"
        :channels="channels"
        @editContent="editContent"
        @editChannels="editChannels"
      />

      <StdcBox
        v-if="stdcDo"
        title="DO"
        color="#E6CE00"
        :show-trigger="true"
        :triggerTitle="$t('15_8_2_1_do_trigger_title')"
        :phase="stdcDo"
        :channels="channels"
        @editContent="editContent"
        @editChannels="editChannels"
      />

      <StdcBox
        v-if="care"
        title="CARE"
        color="#3ABDDA"
        :show-trigger="false"
        triggerTitle=""
        :phase="care"
        :channels="channels"
        @editContent="editContent"
        @editChannels="editChannels"
      />

      <div class="boxed boxed--small">
        <div class="box">
          <div class="column">
            <FormGroup :label="$t('Other Resources')" :required="false">
              <Resources
                :items="resources"
                :disabled="role === 'READ_ONLY'"
                :showError="resourcesIsOpenShowError"
                transCreateNew="15_8_2_1_add_item"
                @updateResources="updateResources"
                @opened="resourcesIsOpen = true"
                @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
              />
            </FormGroup>
          </div>
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalEditChannels"
      @closeModal="showModalEditChannels = false"
    >
      <ModalEditChannels
        :type="editChannelsType"
        :channels="channels"
        :selectedChannels="selectedChannels"
        @close="showModalEditChannels = false"
        @updateChannels="updateChannels"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditImportances"
      @closeModal="showModalEditImportances = false"
    >
      <ModalEditImportances
        @close="showModalEditImportances = false"
        :importances="importances"
        :definedColors="['#17252A', '#9795FF', '#E6CE00', '#3ABDDA']"
        @update="updateImportances"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <StdcModalDelete
        @close="showModalDelete = false"
        :item="stdc"
        @delete="deleteStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditContent"
      @closeModal="showModalEditContent = false"
    >
      <StdcModalEditPhaseContent
        :content="contentToEdit"
        @close="showModalEditContent = false"
        @updateContent="updateContent"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEdit"
      @closeModal="showModalEdit = false"
    >
      <StdcModalAdd
        :stdcToEdit="stdc"
        :selectedPersonas="selectedPersonas"
        :selectedProducts="selectedProducts"
        :personas="personas"
        :products="products"
        @close="showModalEdit = false"
        @update="updateBasic"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showLeaveModal"
      @closeModal="showLeaveModal = false"
    >
      <ModalConfirmClose
        v-if="showLeaveModal"
        @close="handleModalLeaveClose"
        @confirm="handleModalLeaveConfirm"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import NoLeanCanvas from "@/components/sources/components/leanCanvas/NoLeanCanvas.vue";
import Button from "@/components/button/Button.vue";
import LeanCanvasCard from "@/components/sources/components/leanCanvas/LeanCanvasCard.vue";
import LeanCanvasBox from "@/components/sources/components/leanCanvas/LeanCanvasBox.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Label from "@/components/sources/components/label/Label.vue";
import StdcBox from "@/components/sources/components/stdc/StdcBox.vue";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";
import StdcNote from "@/components/sources/components/stdc/StdcNote.vue";
import Modal from "@/components/Modal.vue";
import ModalEditChannels from "@/components/sources/components/channels/ModalEditChannels.vue";
import ModalEditImportances from "@/components/sources/components/ModalEditImportances.vue";
import StdcModalDelete from "@/components/sources/components/stdc/StdcModalDelete.vue";
import COMPANY_STDC from '@/graphql/CompanySTDC.gql';
import STDC_UPDATE from '@/graphql/StdcUpdate.gql';
import STDC_DELETE from '@/graphql/StdcDelete.gql';
import StdcModalEditPhaseContent from "../components/stdc/StdcModalEditPhaseContent.vue";
import utils, {translateStdc} from "../../../utils/utils";
import StdcModalAdd from "../components/stdc/StdcModalAdd.vue";
import {arrayClone} from "../../../services/helpers/clone";
import ModalConfirmClose from "../../ModalConfirmClose.vue";

export default {
  components: {
    ModalConfirmClose,
    StdcModalAdd,
    StdcModalEditPhaseContent,
    StdcModalDelete,
    ModalEditImportances,
    ModalEditChannels,
    Modal,
    StdcNote,
    Resources,
    FormGroup,
    StdcBox,
    Label,
    SlickMenu,
    LeanCanvasBox,
    LeanCanvasCard,
    NoLeanCanvas,
    ContentWrapper,
    IcoPlus,
    Button
  },
  apollo: {
    companies: {
      query: COMPANY_STDC,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const company = data.companies.find((item) => item.id === this.companyID);

        try {
          if(company) {
            this.products = company.products;
            this.personas = company.personas;
            this.channels = company.channels;
          }

          if (company && Array.isArray(company.stdcs)) {
            this.stdc = company.stdcs.find(item => item.id === this.$route.params.stdcId);
            this.originalStdc = arrayClone(this.stdc);
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    selectedProducts() {
      if (!this.stdc.productIds) return [];
      return this.products.filter(product => this.stdc.productIds.includes(product.id));
    },
    selectedPersonas() {
      if (!this.stdc.personaIds) return [];
      return this.personas.filter(persona => this.stdc.personaIds.includes(persona.id));
    },
    importances() {
      return this.stdc.phases.map((phase) => {
        return {
          name: translateStdc(phase.phaseType),
          ratio: phase.phaseData.ratio
        }
      });
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    companyID() {
      return this.$route.params.id;
    },
    see() {
      if (this.stdc.phases) {
        return this.stdc.phases.find((phase) => phase.phaseType === 'SEE') ?? null;
      }

      return null;
    },
    think() {
      if (this.stdc.phases) {
        return this.stdc.phases.find((phase) => phase.phaseType === 'THINK') ?? null;
      }

      return null;
    },
    stdcDo() {
      if (this.stdc.phases) {
        return this.stdc.phases.find((phase) => phase.phaseType === 'DO') ?? null;
      }

      return null;
    },
    care() {
      if (this.stdc.phases) {
        return this.stdc.phases.find((phase) => phase.phaseType === 'CARE') ?? null;
      }

      return null;
    },
    contentHasChanged() {
      return JSON.stringify(this.stdc) !== JSON.stringify(this.originalStdc);
    }
  },
  data() {
    return {
      savingStdc: false,

      selectedChannels: [],
      channels: [],
      editChannelsType: null,

      personaIds: [],
      personas: [],

      productIds: [],
      products: [],

      stdcToEdit: null,
      originalStdc: {
        phases: []
      },
      stdc: {
        phases: []
      },

      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      showModalEditChannels: false,
      showModalEditImportances: false,
      showModalDelete: false,
      showModalEditContent: false,
      showModalEdit: false,
      showLeaveModal: false,

      contentToEdit: null
    }
  },
  methods: {
    handleModalLeaveClose() {
      this.showLeaveModal = false;
    },
    handleModalLeaveConfirm() {
      this.stdc = arrayClone(this.originalStdc);

      this.showLeaveModal = false;
      if (this.nextRoute) {
        this.$router.push(this.nextRoute);
      }
    },
    editChannels(content) {
      this.showModalEditChannels = true;
      this.selectedChannels = content.channels;
      this.editChannelsType = content.phaseType;
    },
    updateChannels(channelIds) {
      const phase = this.stdc.phases.find((phase) => phase.phaseType === this.editChannelsType);
      phase.phaseData.channelIds = channelIds;

      this.showModalEditChannels = false;
      this.editChannelsType = null;
      this.selectedChannels = [];
    },
    editContent(content) {
      this.contentToEdit = content;
      this.showModalEditContent = true;
    },
    updateContent(content) { // content.data, content.type, content.phaseType
      this.showModalEditContent = false;
      const phase = this.stdc.phases.find((phase) => phase.phaseType === content.phaseType);
      phase.phaseData[content.type] = content.data;
    },
    updateBasic(stdc) {
      this.stdc.name = stdc.name;
      this.stdc.productIds = stdc.productIds;
      this.stdc.personaIds = stdc.personaIds;
      this.showModalEdit = false;
    },
    handleEditBasic() {
      this.showModalEdit = true;
    },
    handleGoToLeanCanvas() {
      this.$router.push({ path: `/company/${this.selectedCompany.id}/stdc-detail` })
    },
    updateResources() {

    },
    updateImportances(importances) {
      const phaseMap = new Map(
        this.stdc.phases.map(phase => [phase.phaseType.toUpperCase(), phase])
      );

      importances.forEach(item => {
        const phase = phaseMap.get(item.name.toUpperCase());
        if (phase) {
          phase.phaseData.ratio = item.ratio;
        }
      });

      this.showModalEditImportances = false;
    },
    handleUpdateStdc() {
      this.savingStdc = true;

      const stdc = utils.removeTypename(this.stdc);
      delete stdc.companyId;

      try {
        this.$apollo.mutate({
          mutation: STDC_UPDATE,
          variables: {
            stdc
          },
          update: (store, data) => {
            this.stdc = data.data.updateSTDC;
            this.originalStdc = arrayClone(this.stdc);
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_saved'),
            text: this.$t('15_8_toaster_success_description_saved'),
            type: 'success',
            duration: 5000,
          });

          this.savingStdc = false;
        });
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_saved'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        });

        this.savingStdc = false;
      }
    },
    deleteStdc() {
      try {
        this.$apollo.mutate({
          mutation: STDC_DELETE,
          variables: {
            id: this.stdc.id
          },
          update: (store, data) => {
            this.$router.push({ path: `/company/${this.companyID}/stdc` });
          }
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    confirmLeave(to, from, next) {
      if (this.contentHasChanged) {
        this.showLeaveModal = true;
        this.nextRoute = to;
        next(false);
      } else {
        next();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.confirmLeave(to, from, next);
  },
}
</script>

<style scoped>
.stdc-detail-header-breadcrumb ul{
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 16px;
}

.stdc-detail-header-breadcrumb ul li {
  font-family: Nunito;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
}

.stdc-detail-header-breadcrumb ul li a{
  text-decoration: underline;
  color: #888F91;
}

.stdc-detail-header-breadcrumb ul li span{
  color: #9795FF;
}

.stdc-detail-description {
  margin-top: 12px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
}

.stdc-detail-cards {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.stdc-detail-lists {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.stdc-detail-list {
  max-width: 50%;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.stdc-detail-list:after {
  content: '';
  position: absolute;
  right: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #D1D4D4;
}

.stdc-detail-list:last-child:after {
  display: none;
}

.stdc-detail-list-title {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  color: #17252A;
  flex-shrink: 0;
}

.stdc-detail-list-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}
</style>
