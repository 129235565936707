<template>
  <div class="gauge-container">
    <div class="gauge">
      <!-- Background dial -->
      <div class="gauge-dial"></div>

      <!-- SVG Arrow that rotates based on percentage -->
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{ transform: `rotate(${rotationAngle}deg)` }"
        class="gauge-arrow"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.42002 3.94366C9.78967 3.69853 9.89413 3.20036 9.64904 2.83072C9.40395 2.46107 8.90574 2.35661 8.5361 2.6017C8.24279 2.79857 6.69994 4.22893 4.83967 5.99277C7.19815 4.98425 9.1227 4.13652 9.42002 3.94366ZM0.472258 8.86554C0.259312 8.54411 0.307525 8.1142 0.584757 7.845C1.6294 6.83246 6.88476 1.76197 7.65217 1.25572C8.7611 0.524467 10.2598 0.833842 10.991 1.94679C11.3566 2.50125 11.4611 3.15612 11.3365 3.7588C11.212 4.36148 10.8584 4.92 10.2999 5.28559C9.53654 5.79188 2.7986 8.60438 1.45261 9.16688C1.09503 9.31554 0.685205 9.18697 0.472258 8.86554Z"
          :fill="arrowColor"
        />
      </svg>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100;
      }
    }
  },
  computed: {
    // Calculate the rotation angle (0-100% mapped to -90 to +90 degrees)
    rotationAngle() {
      return (this.percentage / 100) * 180 - 90;
    },
    // Set color gradient from green to red based on percentage
    arrowColor() {
      const red = Math.min(255, (255 * this.percentage) / 100);
      const green = Math.min(255, (255 * (100 - this.percentage)) / 100);
      return `rgb(${red}, ${green}, 50)`; // Adjust green shade as needed
    }
  }
};
</script>


<style scoped>
.gauge-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.gauge {
  position: relative;
  width: 100px;
  height: 50px;
}

.gauge-dial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 5px solid #e0e0e0; /* Gray background for the gauge */
  border-radius: 100px 100px 0 0; /* Creates the semi-circle */
  box-sizing: border-box;
}

.gauge-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform-origin: bottom center; /* Rotates around the bottom of the SVG */
  transition: transform 0.3s ease, fill 0.3s ease;
}
</style>

