<template>
  <div class="lean-canvas-box">
    <div class="lean-canvas-box-header">
      <div class="lean-canvas-box-header-title">
        {{ title }}
      </div>
      <div class="lean-canvas-box-header-edit">
        <Button @click="handleEdit(title, content)" type="ico-only">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.0332 6.33343H5.19987C4.75784 6.33343 4.33392 6.50903 4.02136 6.82159C3.7088 7.13415 3.5332 7.55807 3.5332 8.0001V15.5001C3.5332 15.9421 3.7088 16.3661 4.02136 16.6786C4.33392 16.9912 4.75784 17.1668 5.19987 17.1668H12.6999C13.1419 17.1668 13.5658 16.9912 13.8784 16.6786C14.1909 16.3661 14.3665 15.9421 14.3665 15.5001V14.6668M13.5332 4.66677L16.0332 7.16677M17.1874 5.98759C17.5156 5.65938 17.7 5.21424 17.7 4.75009C17.7 4.28594 17.5156 3.84079 17.1874 3.51259C16.8592 3.18438 16.414 3 15.9499 3C15.4857 3 15.0406 3.18438 14.7124 3.51259L7.69987 10.5001V13.0001H10.1999L17.1874 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </Button>
      </div>
    </div>

    <div class="lean-canvas-box-body">
      <div v-if="content === ''" class="lean-canvas-box-body-description">{{ description }}</div>
      <vuescroll
        v-if="content !== ''"
        :ops="ops"
      >
        <div class="wysiwyg">
          {{ content }}
        </div>
      </vuescroll>
    </div>

    <div class="lean-canvas-box-double" v-if="size === 'double'">
      <div class="lean-canvas-box-header">
        <div class="lean-canvas-box-header-title">
          {{ secondTitle }}
        </div>
        <div class="lean-canvas-box-header-edit">
          <Button @click="handleEdit(secondTitle, secondContent)" type="ico-only">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.0332 6.33343H5.19987C4.75784 6.33343 4.33392 6.50903 4.02136 6.82159C3.7088 7.13415 3.5332 7.55807 3.5332 8.0001V15.5001C3.5332 15.9421 3.7088 16.3661 4.02136 16.6786C4.33392 16.9912 4.75784 17.1668 5.19987 17.1668H12.6999C13.1419 17.1668 13.5658 16.9912 13.8784 16.6786C14.1909 16.3661 14.3665 15.9421 14.3665 15.5001V14.6668M13.5332 4.66677L16.0332 7.16677M17.1874 5.98759C17.5156 5.65938 17.7 5.21424 17.7 4.75009C17.7 4.28594 17.5156 3.84079 17.1874 3.51259C16.8592 3.18438 16.414 3 15.9499 3C15.4857 3 15.0406 3.18438 14.7124 3.51259L7.69987 10.5001V13.0001H10.1999L17.1874 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Button>
        </div>
      </div>

      <div class="lean-canvas-box-body">
        <div v-if="secondContent === ''" class="lean-canvas-box-body-description">{{ secondDescription }}</div>
        <vuescroll
          v-if="secondContent !== ''"
          :ops="ops"
        >
          <div class="wysiwyg">
            {{ secondContent }}
          </div>
        </vuescroll>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/button/Button.vue";
import vuescroll from "vuescroll";

export default {
  components: {
    Button,
    vuescroll,
  },
  props: {
    size: {
      type: String,
      default: 'normal'
    },
    title: {
      type: String,
      default: 'normal'
    },
    description: {
      type: String,
      default: 'normal'
    },
    secondTitle: {
      type: String,
    },
    secondDescription: {
      type: String,
      default: 'normal'
    },
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        }
      },
      content: '',
      secondContent: ''
    }
  },
  methods: {
    handleEdit(title, content) {
      this.$emit('edit', {title, content});
    }
  }
}
</script>

<style>
.lean-canvas-box {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px 1px #0000000D;
  border-radius: 10px;
}

.lean-canvas-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.lean-canvas-box-body {
  height: 257px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.lean-canvas-box-body-description {
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #BFC5C5;
}

.lean-canvas-box-double {
  border-top: 1px solid #E7E9E9;
}

.lean-canvas-box-body .wysiwyg{
  word-wrap: break-word;
  margin-top: 10px;
}

.lean-canvas-box-body .wysiwyg * {
  color: #17252A;
}

.lean-canvas-box-body .wysiwyg h1 {
  text-align: left;
}

.lean-canvas-box-body .wysiwyg p {
  padding: 0;
  margin: 0 0 14px 0;
  color: #17252A;
}

.lean-canvas-box-body .wysiwyg ul,
.lean-canvas-box-body .wysiwyg ol {
  padding-left: 20px;
}

.lean-canvas-box-body .wysiwyg li {

}

.lean-canvas-box-body .wysiwyg a {
  color: #23B39A;
}

.lean-canvas-box-body .wysiwyg iframe {
  max-width: 100% !important;
}

.lean-canvas-box-header-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #17252A;
}
</style>
