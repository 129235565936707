<template>
    <div v-click-outside="closeEdit" @click="toggleEdit" class="keywords" :class="{'keywords--editMode': editMode && !canEdit, 'keywords--focus': editMode && canEdit, 'slim': slim}">

        <div style="display: inline">
            <div class="keywords__tags"
                v-for="(tag, index) in data_items"
                :key="index"
                >
                <span @click="editTag(index)" class="keywords__tag">
                    {{ tag }}<span class="keywords__remove" @click.stop='removeTag(index)'><IcoCross /></span>
                </span>
            </div>
        </div>

        <div @click.stop="canEdit = true" class="keywords__empty-placeholder" v-if="editMode && !canEdit && data_items.length === 0">{{ $t('9_2_none') }}</div>

        <input
            v-if="showData"
            ref="keyword_text"
            type="text"
            :placeholder="placeholder"
            :maxlength="length.short"
            class="keywords__text"
            v-model="content"
            @keydown.enter='addTag'
            @keydown.188='addTag'
            @keydown.delete='removeLastTag'
            @keyup.22="addTags"
            @keyup.ctrl.76="addTags"
            v-autowidth="{maxWidth: '510px', minWidth: '120px', comfortZone: 0}"
            @focus="focused"
            @blur="blurred"
            >
    </div>
</template>


<script>
import textLength from "@/constants/TextLengthConstant.js";
import ClickOutside from 'vue-click-outside';
import IcoCross from '@/components/svg/small/IcoCross.vue';

export default {
    directives: {
        ClickOutside
    },
    components: {
        IcoCross
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            default() {
                return [];
            }
        },
        placeholder: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean,
            default: false
        },
        slim: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        this.data_items = this.value;
        if(this.editMode) {
            this.canEdit = false;
        }
    },
    computed: {
        length() {
            return textLength;
        },
    },
    data() {
        return {
            showData: true,
            data_items: [],
            content: '',
            canEdit: true,
            canBeClosed: true,
        }
    },
    methods: {
        focused() {
            this.$emit('focus');
        },
        blurred() {
            this.$emit('blur');
        },
        toggleEdit() {
            if( this.disabled ) {
                this.canEdit = false
                return
            }

            this.canEdit = true;
        },
        closeEdit() {
            if(this.editMode && this.canBeClosed) {
                this.canEdit = false;
            }
        },
        addTags (event) {
            event.preventDefault();

            var val = event.target.value.trim()
            if (val.length > 0) {
                this.data_items.push(val);
                this.content = '';
            }

            this.$emit("save_changed_value", this.data_items);
        },
        addTag (event) {
            event.preventDefault();

            var val = event.target.value.trim()
            if (val.length > 0) {

                const strings = val.split(',');
                strings.forEach(element => {
                    if(element.length > 0) {
                        this.data_items.push(element.trim());
                    }
                });

                this.content = '';
            }

            this.$emit("save_changed_value", this.data_items);
        },
        removeLastTag(event) {
            if(this.content !== '') {
                return
            }

            if(this.data_items.length === 0) {
                return
            }

            if (event.target.value.length === 0) {
                this.removeTag(this.data_items.length - 1)
            }

            // Update model
            this.$emit("save_changed_value", this.data_items);
        },
        removeTag(index) {
            this.data_items.splice(index, 1);
            this.$emit("save_changed_value", this.data_items);
        },
        editTag(index) {
            if(!this.canEdit) {
                return
            }

            this.canBeClosed = false;

            const toEdit = this.data_items[index]
            this.data_items.splice(index, 1)
            this.$emit("save_changed_value", this.data_items)

            this.content = toEdit;
            this.$refs.keyword_text.focus();

            setTimeout(() => {
                this.canBeClosed = true
            }, 1000)
        }
    },
    watch: {
        value() {
            this.data_items = this.value;
        },
        canEdit() {
            if(this.canEdit) {
                setTimeout(() => {
                    this.$refs.keyword_text.focus();
                }, 200)
            }
        }
    }
}
</script>


<style scoped>
.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.keywords {
    position: relative;
    width: 100%;
    /* border: 1px solid #eee; */
    /* border: 1px solid hsl(170, 74%, 53%); */
    font-size: 0.9em;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 8px;
    /* overflow: hidden; */
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2px;
}

.keywords.slim {
    padding: 0 3px;
}

.keywords--focus{
    border: 1px solid hsl(170, 74%, 53%);
}


.keywords--editMode {
    border: none;
    padding: 0;
    border-radius: none;
}

.keywords__tags{
    display: inline-block;
    position: relative;
    color: #000;
}

.keywords > div:first-child{
    margin-left: 0;
}

.keywords__tag{
    position: relative;
    display: inline-block;
    padding-left: 8px;
    padding-right: 20px;
    margin-right: 6px;
    margin-top: 10px;
    min-height: 22px;
    line-height: 22px;
    background: #E7E9E9;
    border-radius: 3px;
    color: #888F91;
}

.keywords.slim .keywords__tag{
    margin-right: 3px;
    margin-top: 3px;
    font-size: 14px;
    word-break: break-all;
}

.keywords--editMode .keywords__tag{
    padding: 0;
    margin: 0;
    background: transparent;
    border-radius: 0;
    min-height: 20px;
    line-height: 21px;
    margin-right: 5px;
    color: #17252A;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    word-break: break-all;
}

.keywords.slim .keywords__text {
    height: 22px;
    line-height: 22px;
    margin-top: 3px;
}

.keywords--editMode .keywords__tag:after{
    content: ',';
}

.keywords--editMode .keywords__tags:last-child .keywords__tag:after{
    content: '';
}

.keywords__tag span.keywords__remove {
    position: absolute;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    right: 6px;
    top: 1px;
    line-height: 1;
}

.keywords--editMode .keywords__tag span.keywords__remove {
    display: none;
}

.keywords__selected .keywords__empty-placeholder{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #888F91;
    padding-left: 4px;
}

.keywords__empty-placeholder{
    color: #888F91;
    padding-left: 2px;
}

.keywords__text {
    flex: 1;
    display: block;

    height: 30px;
    line-height: 30px;
    margin-top: 6px;

    border: none;
    outline: none;
    font-size: 0.9em;
    background: none;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #17252A;
}

.keywords--editMode .keywords__text {
    display: none;
}

.keywords.keywords--focus.slim {
    padding-bottom: 1px;
}
</style>
