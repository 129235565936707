<template>
  <ContentWrapper>
    <NoLeanCanvas
      v-if="list.length === 0"
      @add="handleAddLeanCanvas"
    />

    <div v-if="list.length > 0" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_4_1_2_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_4_1_2_label_items_count') }}:</div>
            {{ list.length }}
          </div>
          <div class="submenu-items">
            <Button
              @click="showModalEditLeanCanvas = true"
              size="nav"
              type="primary"
            >
              <IcoPlus />
              {{ $t('15_4_1_2_btn_add_canvas') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <LeanCanvasCard />
        </div>
        <pre>{{list}}</pre>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalEditLeanCanvas"
      @closeModal="showModalEditLeanCanvas = false"
    >
      <LeanCanvasEditModal
        :title="'Test title'"
        @close="showModalEditLeanCanvas = false"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import NoLeanCanvas from "@/components/sources/components/leanCanvas/NoLeanCanvas.vue";
import Button from "@/components/button/Button.vue";
import LeanCanvasCard from "@/components/sources/components/leanCanvas/LeanCanvasCard.vue";
import Modal from "@/components/Modal.vue";
import LeanCanvasEditModal from "@/components/sources/components/leanCanvas/LeanCanvasModalEdit.vue";
import COMPANY_LEAN_CANVAS from '@/graphql/CompanyLeanCanvas.gql';

export default {
  components: {
    Modal,
    LeanCanvasCard,
    NoLeanCanvas,
    ContentWrapper,
    IcoPlus,
    Button,
    LeanCanvasEditModal
  },
  apollo: {
    companies: {
      query: COMPANY_LEAN_CANVAS,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if(company) {
            this.list = company.products;
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      list: [],
      showModalEditLeanCanvas: false,
    }
  },
  methods: {
    handleAddLeanCanvas() {

    }
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
