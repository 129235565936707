<template>
  <ContentWrapper>
    <NoStdc
      v-if="list.length === 0"
      @add="handleAddStdc"
    />

    <div v-if="list.length > 0" class="wrapper">
      <div class="short-container">
        <h1>{{ $t('15_8_1_2_title') }}</h1>

        <div class="submenu">
          <div class="submenu-count">
            <div class="submenu-count-label">{{ $t('15_8_1_2_label_items_count') }}:</div>
            {{ list.length }}
          </div>
          <div class="submenu-items">
            <Button
              size="nav"
              type="primary"
              @click="handleAddStdc"
            >
              <IcoPlus/>
              {{ $t('15_8_1_2_btn_add_std') }}
            </Button>
          </div>
        </div>

        <div class="items">
          <StdcCard
            v-for="(item, index) in list"
            :item="item"
            :key="item.id"
            @delete="handleDeleteCard(item)"
          />
        </div>

      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <StdcModalDelete
        :item="stdcToDelete"
        @close="showModalDelete = false"
        @delete="deleteStdc"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalAdd"
      @closeModal="showModalAdd = false"
    >
      <StdcModalAdd
        :personas="personas"
        :products="products"
        :stdcToEdit="null"
        @close="showModalAdd = false"
        @create="createStdc"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import NoPersonas from "@/components/sources/components/personas/NoPersonas.vue";
import PersonaCard from "@/components/sources/components/personas/PersonaCard.vue";
import Button from "@/components/button/Button.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoChartPie from "@/components/svg/IcoChartPie.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import NoProducts from "@/components/sources/components/products/NoProducts.vue";
import NoStdc from "@/components/sources/components/stdc/NoStdc.vue";
import StdcCard from "@/components/sources/components/stdc/StdcCard.vue";
import Modal from "@/components/Modal.vue";
import StdcModalDelete from "@/components/sources/components/stdc/StdcModalDelete.vue";
import StdcModalAdd from "@/components/sources/components/stdc/StdcModalAdd.vue";
import COMPANY_STDC from '@/graphql/CompanySTDC.gql';
import STDC_CREATE from '@/graphql/StdcCreate.gql';
import STDC_DELETE from '@/graphql/StdcDelete.gql';

export default {
  components: {
    StdcModalAdd,
    StdcModalDelete,
    Modal,
    StdcCard,
    NoStdc,
    NoProducts,
    ContentWrapper,
    IcoChartPie,
    PersonaCard,
    NoPersonas,
    Button,
    IcoPlus
  },
  apollo: {
    companies: {
      query: COMPANY_STDC,
      variables() {
        return {
          id: this.companyID,
        }
      },
      update: function(data) {
        const companies = data.companies.filter((item) => item.id === this.companyID);
        const company = companies[0];

        try {
          if(company) {
            this.products = company.products;
            this.personas = company.personas;
          }

          if (company && Array.isArray(company.stdcs)) {
            this.list = company.stdcs;
          }
        } catch(e) {
          this.list = [];
        }
      }
    },
  },
  computed: {
    companyID() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      personas: [],
      products: [],
      list: [],
      showList: false,
      showModalDelete: false,
      stdcToDelete: null,
      showModalAdd: false,
    }
  },
  methods: {
    createStdc(stdc) {
      stdc.companyId = this.companyID;
      console.log("stdc", this.stdc);

      try {
        this.$apollo.mutate({
          mutation: STDC_CREATE,
          variables: {
            stdc: stdc
          },
          update: (store, data) => {
            this.list.push(data.data.createSTDC);
            this.showModalAdd = false;
          }
        });
      } catch(e) {

      }
    },
    deleteStdc(stdc) {
      try {
        this.$apollo.mutate({
          mutation: STDC_DELETE,
          variables: {
            id: stdc.id
          },
          update: (store, data) => {
            this.list.splice(this.list.findIndex((item) => item.id === stdc.id), 1);
            this.showModalDelete = false
          }
        }).then(() => {
          this.$notify({
            title: this.$t('15_8_toaster_success_title_deleted'),
            text: this.$t('15_8_toaster_success_description_deleted'),
            type: 'success',
            duration: 5000,
          })
        })
      } catch(e) {
        this.$notify({
          title: this.$t('15_8_toaster_error_title_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    handleDeleteCard(item) {
      this.showModalDelete = true;
      this.stdcToDelete = item;
      // this.deleteStdc(id, index);
    },
    handleAddStdc() {
      this.showModalAdd = true;
    },
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submenu {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E7E9E9;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.submenu-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  gap: 4px;
}

.submenu-count-label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #AEB3B5;
}

.submenu-items {
  display: flex;
  gap: 16px;
}
</style>
