<template>
  <div class="modal-edit-content">
    <div class="modal-edit-content-header">
      <div class="modal-edit-content-title">
        <strong>{{ $t('15_8_1_3_modal_edit_title') }}</strong>
      </div>
      <button @click="handleCancel" >
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6.5L6 18.5M6 6.5L18 18.5" stroke="#888F91" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-edit-content-body">

      <div class="column">
        <FormGroup
          :label="$t('15_8_1_3_label_title')"
          :required="true"
          :focused="focusName"
          :validationError="validationError.name"
        >
          <input
            type="text"
            :placeholder="$t('15_8_1_3_placeholder_title')"
            v-model="stdc.name"
            @input="validationError.name = false"
            @focus="focusName = true"
            @blur="focusName = false"
            ref="contentName"
          />
        </FormGroup>
      </div>

      <div class="column">
        <FormGroup
          :label="$t('15_8_1_3_label_description')"
          :required="false"
        >
          <textarea
            class="form-group__input large"
            :placeholder="$t('15_8_1_3_placeholder_description')"
            v-model="description"
          ></textarea>
        </FormGroup>
      </div>

      <div class="column"
           v-for="(persona, index) in personaList"
           :key="`persona-${index}`"
      >
        <Dropdown
          v-model="persona.value"
          :label="$t('15_8_1_3_label_competitors')"
          :placeholder="$t('15_8_1_3_placeholder_competitors')"
          :items="personasValues"
        />
        <ButtonAdd v-if="index === personaList.length - 1" @click="handleAddPersonaItem" />
        <ButtonRemove v-else @click="handleRemovePersonaItem(index)" />
      </div>

      <div class="column"
           v-for="(product, index) in productList"
           :key="`product-${index}`"
      >
        <Dropdown
          v-model="product.value"
          :label="$t('15_8_1_3_label_product_and_services')"
          :placeholder="$t('15_8_1_3_placeholder_product_and_services')"
          :items="productValues"
        />
        <ButtonAdd v-if="index === productList.length - 1" @click="handleAddProductItem" />
        <ButtonRemove v-else @click="handleRemoveProductItem(index)" />
      </div>
    </div>

    <div class="modal-edit-content-footer">
      <Button @click="handleCancel" type="secondary">{{ $t('15_8_1_3_modal_edit_btn_cancel') }}</Button>
      <Button v-if="type === 'create'" @click="handleCreate" type="primary">{{ $t('15_8_1_3_modal_edit_btn_save') }}</Button>
      <Button v-if="type === 'edit'" @click="handleUpdate" type="primary">{{ $t('15_8_1_3_modal_edit_btn_edit') }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/Button.vue";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import TextAreaEditor from "@/components/TextAreaEditor.vue";
import textLength from "@/constants/TextLengthConstant";
import WYSIWYGEditor from "@/components/WYSIWYGEditor.vue";
import TinymceEditor from "@/components/TinymceEditor.vue";
import FormGroup from "@/components/FormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";
import ButtonRemove from "@/components/buttonRemove/ButtonRemove.vue";
import {obj_clone} from "../../../../services/helpers/clone";
export default {
  components: {
    ButtonAdd,
    ButtonRemove,
    CompanyLogo,
    FormGroup,
    TinymceEditor,
    WYSIWYGEditor,
    TextAreaEditor,
    Button,
    Dropdown
  },
  computed: {
    type() {
      if(this.stdcToEdit !== null) {
        return 'edit';
      }
      return 'create';
    },
    length() {
      return textLength
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    personasValues() {
      const selectedPersonaIds = this.personaList.map(persona => persona.value.value);

      return this.personas
        .filter(persona => !selectedPersonaIds.includes(persona.id))
        .map(persona => ({
          name: persona.name,
          value: persona.id
        }));
    },
    personasToSave() {
      return this.personaList
        .filter((persona) => persona.value.value)
        .map((persona) => persona.value.value);
    },
    productValues() {
      const selectedProductsIds = this.productList.map(product => product.value.value);

      return this.products
        .filter(product => !selectedProductsIds.includes(product.id))
        .map(product => ({
          name: product.name,
          value: product.id
        }));
    },
    productsToSave() {
      return this.productList
        .filter((product) => product.value.value)
        .map((product) => product.value.value);
    },
  },
  props: [
    'stdcToEdit',
    'personas',
    'products',
    'selectedPersonas',
    'selectedProducts'
  ],
  created() {
    if (this.stdcToEdit) {
      this.stdc.name = this.stdcToEdit.name;
      this.stdc.productIds = this.stdcToEdit.productIds;
      this.stdc.personaIds = this.stdcToEdit.personaIds;
    }

    if (this.selectedPersonas.length > 0) {
      this.personaList = [];
      this.selectedPersonas.forEach((item) => {
        this.personaList.push({value: {name: item.name, value: item.id}})
      });
    }

    if (this.selectedProducts.length > 0) {
      this.productList = [];
      this.selectedProducts.forEach((item) => {
        this.productList.push({value: {name: item.name, value: item.id}})
      });
    }
  },
  data() {
    return {
      emptyRecord: {
        name: null,
        value: ''
      },
      personaList: [{value: obj_clone(this.emptyRecord)}],
      productList: [{value: obj_clone(this.emptyRecord)}],
      description: '',
      focusName: false,
      validationError: {
        name: false,
      },
      content: '',
      contentIsOpenShowError: false,
      contentIsOpen: true,
      stdc: {
        name: '',
        // description: 'Some description',
        companyId: '',
        personaIds: [],
        phases: [
          {
            phaseType: 'SEE',
            phaseData: {

              channelIds: [],
              ratio: 25,
              state: '',
              action: '',
              reaction: '',
              trigger: ''
            }
          },
          {
            phaseType: 'THINK',
            phaseData: {
              action: '',
              channelIds: [],
              ratio: 25,
              reaction: '',
              state: '',
              trigger: ''
            }
          },
          {
            phaseType: 'DO',
            phaseData: {
              action: '',
              channelIds: [],
              ratio: 25,
              reaction: '',
              state: '',
              trigger: ''
            }
          },
          {
            phaseType: 'CARE',
            phaseData: {
              action: '',
              channelIds: [],
              ratio: 25,
              reaction: '',
              state: '',
              trigger: ''
            }
          }
        ],
        productIds: [],
      },
      selectedPersona: {}
    }
  },
  methods: {
    handleRemoveProductItem(index) {
      this.productList.splice(index,1);
    },
    handleRemovePersonaItem(index) {
      this.personaList.splice(index,1);
    },
    handleAddPersonaItem() {
      const empty = obj_clone(this.emptyRecord);
      this.personaList.push({value: empty});
    },
    handleAddProductItem() {
      const empty = obj_clone(this.emptyRecord);
      this.productList.push({value: empty});
    },
    handleCancel() {
      this.$emit('close');
    },
    handleClose() {
      this.$emit('close');
    },
    saveEditName() {

    },
    handleCreate() {
      if (this.stdc.name === '') {
        this.validationError.name = true;
        return;
      }

      this.stdc.productIds = this.productsToSave;
      this.stdc.personaIds = this.personasToSave;

      this.$emit('create', this.stdc);
    },
    handleUpdate() {
      if (this.stdc.name === '') {
        this.validationError.name = true;
        return;
      }

      this.stdc.productIds = this.productsToSave;
      this.stdc.personaIds = this.personasToSave;

      this.$emit('update', this.stdc);
    }
  }
}
</script>

<style scoped>
.form-group__input.large {
  height: 100px;
}

.modal-edit-content {
  width: 600px;
}

.modal-edit-content-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-edit-content-header button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.modal-edit-content-title {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  text-align: left;
  color: #17252A;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.modal-edit-content-title span {
  position: relative;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  color: #888F91;
}

.modal-edit-content-title span:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 4px;
  bottom: 4px;
  width: 1px;
  background-color: #888F91;
}

.modal-edit-content-body {
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.modal-edit-content-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  border-top: 1px solid #E7E9E9;
  padding: 32px;
}
</style>
