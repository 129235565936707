<template>
  <ContentWrapper disable-top-offset="true">

    <SlickMenu :title="'Kofola'" >
      <Button @click="showModalDelete = true" size="nav" type="delete">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_7_2_1_btn_delete') }}
      </Button>
      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_7_2_1_btn_print') }}
      </Button>
      <Button size="nav" type="primary">{{ $t('15_7_2_1_btn_save') }}</Button>
    </SlickMenu>

    <div class="product-detail">
      <div class="product-detail-body">
        <div class="column">
          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{  $t('15_7_2_1_title_main_info') }}</h2>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_product_service_name')" :required="true">
                  <input
                    type="text"
                    :placeholder="$t('15_7_2_1_placeholder_product_service_name')"
                    v-model="title"
                    @input="validationError.title = false"
                    ref="contentName"
                  />
                </FormGroup>
                <div class="col-full">
                  <CompanyLogo :title="$t('15_7_2_1_label_product_service_image')" />
                </div>
              </div>

              <div class="">
                <FormGroup
                  :label="$t('15_7_2_1_label_key_insight')"
                  :required="false"
                  :validationError="validationError.title"
                >
                  <input
                    type="text"
                    :placeholder="$t('15_7_2_1_placeholder_key_insight')"
                    v-model="title"
                    @input="validationError.title = false"
                    ref="contentName"
                  />
                </FormGroup>
                <Hint>{{ $t('15_7_2_1_hint_key_insight') }}</Hint>
              </div>

              <div class="column">
                <Dropdown
                  :label="$t('15_7_2_1_label_average_price')"
                  :placeholder="$t('15_7_2_1_placeholder_average_price')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
              </div>

              <div class="column">
                <Dropdown
                  :label="$t('15_7_2_1_label_personas')"
                  :placeholder="$t('15_7_2_1_placeholder_personas')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
                <ButtonAdd />
              </div>

              <div class="column">
                <Dropdown
                  :label="$t('15_7_2_1_label_channels')"
                  :placeholder="$t('15_7_2_1_placeholder_channels')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
                <ButtonAdd />
              </div>

              <div class="column">
                <Dropdown
                  :label="$t('15_7_2_1_label_competitors')"
                  :placeholder="$t('15_7_2_1_placeholder_competitors')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
                <ButtonAdd />
              </div>

              <div>
                <STDCFrameworksAndPhases />
              </div>

              <Accordion
                :title="$t('15_7_2_1_accordion_title')"
                :description="$t('15_7_2_1_accordion_description')"
              >
                <div class="column">
                  <Dropdown
                    :label="$t('15_7_2_1_label_type')"
                    :placeholder="$t('15_7_2_1_placeholder_type')"
                    :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                  />
                </div>

                <div class="column">
                  <FormGroup :label="$t('15_7_2_1_label_marker_location')" :required="false">
                    <input
                      type="text"
                      :placeholder="$t('15_7_2_1_placeholder_marker_location')"
                      v-model="title"
                      @input="validationError.title = false"
                      ref="contentName"
                    />
                  </FormGroup>
                </div>

                <div class="column">
                  <FormGroup :label="$t('15_7_2_1_label_total_addressable_marker')" :required="false">
                    <input
                      type="text"
                      :placeholder="$t('15_7_2_1_placeholder_total_addressable_marker')"
                      v-model="title"
                      @input="validationError.title = false"
                      ref="contentName"
                    />
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>

          <div class="boxed boxed--full">
            <div class="box">
              <h2>{{  $t('15_7_2_1_other_info') }}</h2>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_description')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_description')"
                      v-model="description"
                      @input="validationError.description = false"
                      ref="contentName"
                    ></textarea>
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_argumantation_beneftis')" :required="false">
                  <Resources
                    :items="resources"
                    :disabled="role === 'READ_ONLY'"
                    :showError="resourcesIsOpenShowError"
                    transCreateNew="15_7_2_1_add_argumantation_beneftis"
                    @updateResources="updateResources"
                    @opened="resourcesIsOpen = true"
                    @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                  />
                </FormGroup>
              </div>

              <div class="column">
                <FormGroup :label="$t('15_7_2_1_label_unique_selling_proposition')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_unique_selling_proposition')"
                      v-model="description"
                      @input="validationError.description = false"
                      ref="contentName"
                    ></textarea>
                </FormGroup>
              </div>

              <Accordion
                :title="$t('15_7_2_1_accordion_other_title')"
                :description="$t('15_7_2_1_accordion_other_description')"
              >
                <div class="column">
                  <FormGroup :label="$t('15_7_2_1_label_note')" :required="false">
                    <textarea
                      class="form-group__input large"
                      :placeholder="$t('15_7_2_1_placeholder_note')"
                      v-model="description"
                      @input="validationError.description = false"
                      ref="contentName"
                    ></textarea>
                  </FormGroup>
                </div>

                <div class="column">
                  <FormGroup :label="$t('15_7_2_1_label_other_resources')" :required="false">
                    <Resources
                      :items="resources"
                      :disabled="role === 'READ_ONLY'"
                      :showError="resourcesIsOpenShowError"
                      transCreateNew="15_7_2_1_add_other_resources"
                      @updateResources="updateResources"
                      @opened="resourcesIsOpen = true"
                      @closed="
                      ;(resourcesIsOpen = false),
                        (resourcesIsOpenShowError = false)
                    "
                    />
                  </FormGroup>
                </div>
              </Accordion>

            </div>
          </div>

        </div>
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <ProductsModalDelete @close="showModalDelete = false" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Button from "@/components/button/Button.vue";
import FormGroup from "@/components/FormGroup.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import Avatar from "@/components/Avatar.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import Hint from "@/components/sources/components/hint/Hint.vue";
import ButtonAdd from "@/components/buttonCopy/ButtonAdd.vue";
import STDCFrameworksAndPhases
  from "@/components/sources/components/stdcFrameworksAndPhases/STDCFrameworksAndPhases.vue";
import ProductsModalDelete from "@/components/sources/components/products/ProductsModalDelete.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
    ProductsModalDelete,
    STDCFrameworksAndPhases,
    ButtonAdd,
    Hint,
    SlickMenu,
    ContentWrapper,
    CompanyLogo,
    Accordion,
    Resources,
    FormGroup,
    Button,
    Avatar,
    Dropdown
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  data() {
    return {
      resources: [],
      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      description: '',
      title: '',
      validationError: {
        description: false,
        title: false,
      },
      showModalDelete: false
    }
  },
  methods: {
    updateResources() {

    },
    handleGoBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.product-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-detail-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.product-detail-header-back {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.product-detail-header-title {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 800;
  line-height: 33.6px;
  text-align: left;
}

.product-detail-header-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.col-full {
  width: 100%;
}
</style>
