function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => removeTypename(item));
  } else if (typeof obj === 'object') {
    const newObj = {};
    for (let key in obj) {
      if (key !== '__typename') {
        newObj[key] = removeTypename(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
}

const translateStdc = (item) => {
  if(item === 'SEE') {
    return 'See';
  }

  if(item === 'THINK') {
    return 'Think';
  }

  if(item === 'DO') {
    return 'Do';
  }

  if(item === 'CARE') {
    return 'Care';
  }

  return item;
}

module.exports = {
  removeTypename,
  translateStdc
}
