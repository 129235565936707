import { render, staticRenderFns } from "./StdcBox.vue?vue&type=template&id=dbca34ba&scoped=true&"
import script from "./StdcBox.vue?vue&type=script&lang=js&"
export * from "./StdcBox.vue?vue&type=script&lang=js&"
import style0 from "./StdcBox.vue?vue&type=style&index=0&id=dbca34ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbca34ba",
  null
  
)

export default component.exports