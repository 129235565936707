<template>
  <div class="sources-menu">
    <a
      v-for="item in items"
      :key="item.name"
      class="sources-menu-item"
      :class="{active: selectedItem === item.id || item.subitems.find((i) => i === selectedItem)}"
      @click="handleSetPage(item)"
      v-tooltip.right="!sourceSidebarState ? item.name : ''"
    >
      <div class="sources-menu-item-ico">
        <component :is="item.ico" />
      </div>
      <div class="sources-menu-item-title">
        {{ item.name }}
      </div>
    </a>

  </div>
</template>

<script>

import IconCompany from "@/components/sources/icons/IconCompany.vue";
import IconBrandAndIdentity from "@/components/sources/icons/IconPeopleAndTeam.vue";
import IconPeopleAndTeam from "@/components/sources/icons/IconPeopleAndTeam.vue";
import IconLeanCanvas from "@/components/sources/icons/IconLeanCanvas.vue";
import IconToneOfVoice from "@/components/sources/icons/IconToneOfVoice.vue";
import IconPersonasAndAudiences from "@/components/sources/icons/IconPersonasAndAudiences.vue";
import IconProductsAndServices from "@/components/sources/icons/IconProductsAndServices.vue";
import IconSTDC from "@/components/sources/icons/IconSTDC.vue";
import IconChannels from "@/components/sources/icons/IconChannels.vue";
import IconCompetitors from "@/components/sources/icons/IconCompetitors.vue";
import IconMeetingNotes from "@/components/sources/icons/IconMeetingNotes.vue";
import IconBudgets from "@/components/sources/icons/IconBudgets.vue";
import IconSourceManagement from "@/components/sources/icons/IconSourceManagement.vue";

export default {
  components: {

  },
  created() {
    this.selectedItem = this.$route.name;
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    sourceSidebarState() {
      return this.$store.getters.getSourceSidebarState
    },
  },
  data() {
    return {
      selectedItem: '',
      items: [
        {
          id: 'CompanyInfo',
          ico: IconCompany,
          name: this.$t("15_0_1_1_menu_company_info"),
          link: 'company-info',
          subitems: []
        },
        {
          id: 'PeopleAndTeam',
          ico: IconPeopleAndTeam,
          name: this.$t("15_0_1_1_menu_people_and_team"),
          link: 'people-and-team',
          subitems: []
        },
        {
          id: 'BrandAndIdentity',
          ico: IconBrandAndIdentity,
          name: this.$t("15_0_1_1_menu_brand_and_identity"),
          link: 'brand-and-identity',
          subitems: []
        },
        {
          id: 'LeanCanvas',
          ico: IconLeanCanvas,
          name: this.$t("15_0_1_1_menu_lean_canvas"),
          link: 'lean-canvas',
          subitems: ['LeanCanvasDetail']
        },
        {
          id: 'ToneOfVoice',
          ico: IconToneOfVoice,
          name: this.$t("15_0_1_1_menu_tone_of_voice"),
          link: 'tone-of-voice',
          subitems: []
        },
        {
          id: 'PersonasAndAudiences',
          ico: IconPersonasAndAudiences,
          name: this.$t("15_0_1_1_menu_personas_and_audiences"),
          link: 'personas-and-audiences',
          subitems: ['PersonasAndAudiencesDetail']
        },
        {
          id: 'ProductsAndServices',
          ico: IconProductsAndServices,
          name: this.$t("15_0_1_1_menu_products_and_services"),
          link: 'products-and-services',
          subitems: ['ProductsAndServicesDetail']
        },
        {
          id: 'Stdc',
          ico: IconSTDC,
          name: this.$t("15_0_1_1_menu_stdc"),
          link: 'stdc',
          subitems: ['StdcDetail']
        },
        {
          id: 'Channels',
          ico: IconChannels,
          name: this.$t("15_0_1_1_menu_channels"),
          link: 'channels',
          subitems: ['ChannelDetail']
        },
        {
          id: 'Competitors',
          ico: IconCompetitors,
          name: this.$t("15_0_1_1_menu_competitors"),
          link: 'competitors',
          subitems: ['CompetitorsDetail']
        },
        {
          id: 'MeetingNotes',
          ico: IconMeetingNotes,
          name: this.$t("15_0_1_1_menu_meeting_notes"),
          link: 'meeting-notes',
          subitems: []
        },
        {
          id: 'Budgets',
          ico: IconBudgets,
          name: this.$t("15_0_1_1_menu_budgets"),
          link: 'budgets',
          subitems: []
        },
        {
          id: 'StorageManagement',
          ico: IconSourceManagement,
          name: this.$t("15_13_menu_storage_management"),
          link: 'storage-management',
          subitems: []
        }
      ]
    }
  },
  methods: {
    handleSetPage(item) {
      this.$router.push({ path: `/company/${this.selectedCompany.id}/${item.link}` }).then(() => {
        this.selectedItem = item.id;
      });
    }
  },
  watch: {

  },
}
</script>

<style>
.sources-menu {
  width: 100%;
  margin-top: 10px;
}

.sources-menu-item {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 44px;
  gap: 10px;
  padding-left: 20px;
}

.sources-menu-item:hover {
  color: #2CE0C1;
}

.sources-menu-item.active {
  background: #2CE0C114;
}

.sources-menu-item.active:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #2CE0C1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sources-menu-item-ico {
  line-height: 1;
}

.sources-menu-item-ico svg path {
  stroke: #888F91 !important;
}

.sources-menu-item:hover .sources-menu-item-ico svg path {
  stroke: #2CE0C1 !important;
}

.sources-menu-item.active .sources-menu-item-ico svg path {
  stroke: #1F9D87 !important;
}

.sources-menu-item.active:hover .sources-menu-item-ico svg path {
  stroke: #1A8674 !important;
}

.sources-menu-item-title {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: left;
  line-height: 1;
}

.sources-sidebar.collapsed .sources-menu-item-title {
  display: none;
}

.sources-menu-item.active .sources-menu-item-title {
  color: #1F9D87;
}

.sources-menu-item.active:hover .sources-menu-item-title {
  color: #1A8674;
}

.sources-menu-item:hover .sources-menu-item-title {
  color: #2CE0C1;
}
</style>
