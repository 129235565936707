<template>
  <ContentWrapper :disable-top-offset="true">

    <SlickMenu
      title="Lean Canvas Title #1"
    >
      <Button
        size="nav"
        type="secondary"
        @click="showModalEditLeanCanvas = true"
      >
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83301 6.33343H4.99967C4.55765 6.33343 4.13372 6.50903 3.82116 6.82159C3.5086 7.13415 3.33301 7.55807 3.33301 8.0001V15.5001C3.33301 15.9421 3.5086 16.3661 3.82116 16.6786C4.13372 16.9912 4.55765 17.1668 4.99967 17.1668H12.4997C12.9417 17.1668 13.3656 16.9912 13.6782 16.6786C13.9907 16.3661 14.1663 15.9421 14.1663 15.5001V14.6668M13.333 4.66677L15.833 7.16677M16.9872 5.98759C17.3154 5.65938 17.4998 5.21424 17.4998 4.75009C17.4998 4.28594 17.3154 3.84079 16.9872 3.51259C16.659 3.18438 16.2138 3 15.7497 3C15.2855 3 14.8404 3.18438 14.5122 3.51259L7.49967 10.5001V13.0001H9.99967L16.9872 5.98759Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_4_2_1_btn_edit') }}
      </Button>

      <Button size="nav" type="secondary">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1667 14.1667H15.8333C16.2754 14.1667 16.6993 13.9911 17.0118 13.6785C17.3244 13.366 17.5 12.942 17.5 12.5V9.16667C17.5 8.72464 17.3244 8.30072 17.0118 7.98816C16.6993 7.67559 16.2754 7.5 15.8333 7.5H4.16667C3.72464 7.5 3.30072 7.67559 2.98816 7.98816C2.67559 8.30072 2.5 8.72464 2.5 9.16667V12.5C2.5 12.942 2.67559 13.366 2.98816 13.6785C3.30072 13.9911 3.72464 14.1667 4.16667 14.1667H5.83333M14.1667 7.5V4.16667C14.1667 3.72464 13.9911 3.30072 13.6785 2.98816C13.366 2.67559 12.942 2.5 12.5 2.5H7.5C7.05797 2.5 6.63405 2.67559 6.32149 2.98816C6.00893 3.30072 5.83333 3.72464 5.83333 4.16667V7.5M5.83333 12.5C5.83333 12.058 6.00893 11.634 6.32149 11.3215C6.63405 11.0089 7.05797 10.8333 7.5 10.8333H12.5C12.942 10.8333 13.366 11.0089 13.6785 11.3215C13.9911 11.634 14.1667 12.058 14.1667 12.5V15.8333C14.1667 16.2754 13.9911 16.6993 13.6785 17.0118C13.366 17.3244 12.942 17.5 12.5 17.5H7.5C7.05797 17.5 6.63405 17.3244 6.32149 17.0118C6.00893 16.6993 5.83333 16.2754 5.83333 15.8333V12.5Z" stroke="#17252A" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_4_2_1_btn_print') }}
      </Button>

      <Button
        size="nav"
        type="delete"
        @click="showModalDelete = true"
      >
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33398 4.83333H14.6673M6.33398 8.16667V13.1667M9.66732 8.16667V13.1667M2.16732 4.83333L3.00065 14.8333C3.00065 15.2754 3.17625 15.6993 3.48881 16.0118C3.80137 16.3244 4.22529 16.5 4.66732 16.5H11.334C11.776 16.5 12.1999 16.3244 12.5125 16.0118C12.8251 15.6993 13.0007 15.2754 13.0007 14.8333L13.834 4.83333M5.50065 4.83333V2.33333C5.50065 2.11232 5.58845 1.90036 5.74473 1.74408C5.90101 1.5878 6.11297 1.5 6.33398 1.5H9.66732C9.88833 1.5 10.1003 1.5878 10.2566 1.74408C10.4129 1.90036 10.5007 2.11232 10.5007 2.33333V4.83333" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ $t('15_4_2_1_btn_delete') }}
      </Button>
    </SlickMenu>

    <div class="lean-canvas-description">Lorem ipsum dolor sit amet, has eros nobis facilisi ea, sit eu delicata mandamus perpetua, oportere maluisset reprimique in vel.</div>

    <div class="lean-canvas-cards">
      <div class="column">
        <LeanCanvasBox
          @edit="handleEdit"
          size="double"
          :title="$t('15_4_2_1_title_problems')"
          :description="$t('15_4_2_1_description_problems')"
          :secondTitle="$t('15_4_2_1_title_existing_alternatives')"
          :secondDescription="$t('15_4_2_1_description_existing_alternatives')"
        />

        <div class="row row--full">
          <LeanCanvasBox
            @edit="handleEdit"
            :title="$t('15_4_2_1_title_solutions')"
            :description="$t('15_4_2_1_description_solutions')"
          />
          <LeanCanvasBox
            @edit="handleEdit"
            :title="$t('15_4_2_1_title_key_metricts')"
            :description="$t('15_4_2_1_description_key_metricts')"
          />
        </div>

        <LeanCanvasBox
          @edit="handleEdit"
          size="double"
          :title="$t('15_4_2_1_title_usp')"
          :description="$t('15_4_2_1_description_usp')"
          :secondTitle="$t('15_4_2_1_title_high_level_concept')"
          :secondDescription="$t('15_4_2_1_description_high_level_concept')"
        />

        <div class="row row--full">
          <LeanCanvasBox
            @edit="handleEdit"
            :title="$t('15_4_2_1_title_unfair_advantage')"
            :description="$t('15_4_2_1_title_unfair_advantage')"
          />
          <LeanCanvasBox
            @edit="handleEdit"
            :title="$t('15_4_2_1_title_channels')"
            :description="$t('15_4_2_1_description_channels')"
          />
        </div>

        <LeanCanvasBox
          @edit="handleEdit"
          size="double"
          :title="$t('15_4_2_1_title_unfair_advantage')"
          :description="$t('15_4_2_1_title_unfair_advantage')"
          :secondTitle="$t('15_4_2_1_title_early_adopters')"
          :secondDescription="$t('15_4_2_1_description_early_adopters')"
        />
      </div>

      <div class="column column--full column--top">
        <LeanCanvasBox
          @edit="handleEdit"
          :title="$t('15_4_2_1_title_cost_structure')"
          :description="$t('15_4_2_1_description_cost_structure')"
        />
        <LeanCanvasBox
          @edit="handleEdit"
          :title="$t('15_4_2_1_title_revenue_streams')"
          :description="$t('15_4_2_1_description_revenue_streams')"
        />
      </div>
    </div>

    <Modal
      type="source"
      v-if="showModalDelete"
      @closeModal="showModalDelete = false"
    >
      <LeanCanvasModalDelete
        :title="title"
        @close="showModalDelete = false"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditLeanCanvas"
      @closeModal="showModalEditLeanCanvas = false"
    >
      <LeanCanvasEditModal
        :title="title"
        @close="showModalEditLeanCanvas = false"
      />
    </Modal>

    <Modal
      type="source"
      v-if="showModalEditContent"
      @closeModal="showModalEditContent = false"
    >
      <ModalEditHtml
        @close="showModalEditContent = false"
        :title="showModalEditContentTitle"
        :content="showModalEditContentData"
      />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ModalEditHtml from "@/components/sources/components/ModalEditHtml.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";
import IcoPlus from '@/components/svg/IcoPlus.vue';
import NoLeanCanvas from "@/components/sources/components/leanCanvas/NoLeanCanvas.vue";
import Button from "@/components/button/Button.vue";
import LeanCanvasCard from "@/components/sources/components/leanCanvas/LeanCanvasCard.vue";
import LeanCanvasBox from "@/components/sources/components/leanCanvas/LeanCanvasBox.vue";
import Modal from "@/components/Modal.vue";
import ModalEditImportances from "@/components/sources/components/ModalEditImportances.vue";
import SlickMenu from "@/components/sources/components/SlickMenu.vue";
import LeanCanvasEditModal from "@/components/sources/components/leanCanvas/LeanCanvasModalEdit.vue";
import LeanCanvasModalDelete from "@/components/sources/components/leanCanvas/LeanCanvasModalDelete.vue";

export default {
  components: {
    LeanCanvasModalDelete,
    LeanCanvasEditModal,
    SlickMenu,
    ModalEditHtml,
    ModalEditImportances,
    Modal,
    LeanCanvasBox,
    LeanCanvasCard,
    NoLeanCanvas,
    ContentWrapper,
    IcoPlus,
    Button
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  data() {
    return {
      showList: true,
      showModalEditContent: false,
      showModalEditContentTitle: '',
      showModalEditContentData: '',

      showModalEditLeanCanvas: false,
      showModalDelete: false,

      title: ''
    }
  },
  methods: {
    handleGoToLeanCanvas() {
      this.$router.push({ path: `/company/${this.selectedCompany.id}/lean-canvas` })
    },
    handleEdit(args) {
      console.log("args: ", args);
      this.showModalEditContentTitle = args.title;
      this.showModalEditContentData = args.content;
      this.showModalEditContent = true;
    }
  }
}
</script>

<style scoped>
.lean-canvas-header-breadcrumb ul{
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 16px;
}

.lean-canvas-header-breadcrumb ul li {
  font-family: Nunito;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
}

.lean-canvas-header-breadcrumb ul li a{
  text-decoration: underline;
  color: #888F91;
}

.lean-canvas-header-breadcrumb ul li span{
  color: #9795FF;
}

.lean-canvas-description {
  margin-top: 12px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #888F91;
  margin-top: 57px;
}

.lean-canvas-cards {
  margin-top: 24px;
}
</style>
