<template>
  <div>
    <div class="stdc-box">
      <div class="stdc-box-header">
        <div class="stdc-box-header-title">
          <div class="stdc-box-header-title-ico" :style="{'background-color': color}"></div>
          <div class="stdc-box-header-title-value">
            {{ phase.phaseType }}
          </div>
        </div>
        <div class="stdc-box-header-rating">
          {{ phase.phaseData.ratio }}%
        </div>
      </div>
      <div class="stdc-box-body">
        <div class="stdc-box-body-line" :style="{'background-color': color}"></div>

        <div class="stdc-box-body-col stdc-box-body-col--padding">
          <div class="stdc-box-body-col-header">
            {{ $t('15_8_2_1_label_channels') }}
            <button @click="handleEditChannels">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <div class="stdc-box-body-col-body">
            <vuescroll :ops="ops">
              <div class="stdc-box-body-col-body-channels">
                <Label
                  v-for="(channel, index) in selectedChannels"
                  :key="`channel-${index}`"
                  :title="channel.name"
                />
              </div>
            </vuescroll>
          </div>
        </div>

        <div class="stdc-box-body-col">
          <div class="stdc-box-body-col-header">
            {{ $t('15_8_2_1_label_what_is_the_situation') }}
            <button @click="handleEditContent(phase.phaseData.state, 'state')">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <div class="stdc-box-body-col-body">
            <vuescroll :ops="ops">
              <div class="wysiwyg" v-html="phase.phaseData.state"></div>
            </vuescroll>
          </div>
        </div>

        <div class="stdc-box-body-col">
          <div class="stdc-box-body-col-header">
            {{ $t('15_8_2_1_label_how_it_has_been_solved') }}
            <button @click="handleEditContent(phase.phaseData.action, 'action')">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <div class="stdc-box-body-col-body">
            <vuescroll :ops="ops">
              <div class="wysiwyg" v-html="phase.phaseData.action"></div>
            </vuescroll>
          </div>
        </div>

        <div class="stdc-box-body-col">
          <div class="stdc-box-body-col-header">
            {{ $t('15_8_2_1_label_how_can_we_help') }}
            <button @click="handleEditContent(phase.phaseData.reaction, 'reaction')">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83203 6.33343H4.9987C4.55667 6.33343 4.13275 6.50903 3.82019 6.82159C3.50763 7.13415 3.33203 7.55807 3.33203 8.0001V15.5001C3.33203 15.9421 3.50763 16.3661 3.82019 16.6786C4.13275 16.9912 4.55667 17.1668 4.9987 17.1668H12.4987C12.9407 17.1668 13.3646 16.9912 13.6772 16.6786C13.9898 16.3661 14.1654 15.9421 14.1654 15.5001V14.6668M13.332 4.66677L15.832 7.16677M16.9862 5.98759C17.3144 5.65938 17.4988 5.21424 17.4988 4.75009C17.4988 4.28594 17.3144 3.84079 16.9862 3.51259C16.658 3.18438 16.2129 3 15.7487 3C15.2845 3 14.8394 3.18438 14.5112 3.51259L7.4987 10.5001V13.0001H9.9987L16.9862 5.98759Z" stroke="#2CE0C1" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <div class="stdc-box-body-col-body">
            <vuescroll :ops="ops">
              <div class="wysiwyg" v-html="phase.phaseData.reaction"></div>
            </vuescroll>
          </div>
        </div>
      </div>
    </div>

    <StdcNote
      v-if="showTrigger"
      :title="triggerTitle"
      :content="phase.phaseData.trigger"
      @update="updateTrigger"
    />
  </div>
</template>

<script>
import vuescroll from "vuescroll";
import StdcNote from "./StdcNote.vue";
import channels from "../../views/Channels.vue";
import Label from "../label/Label.vue";
export default {
  components: {
    Label,
    StdcNote,
    vuescroll
  },
  computed: {
    selectedChannels() {
      if (!this.phase.phaseData.channelIds) return [];
      return this.channels.filter(channel => this.phase.phaseData.channelIds.includes(channel.id));
    },
  },
  props: ['phase', 'title', 'color', 'showTrigger', 'channels', 'triggerTitle'],
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        }
      },
    }
  },
  methods: {
    handleEditContent(content, type) {
      this.$emit('editContent', {data: content, type, phaseType: this.phase.phaseType});
    },
    handleEditChannels() {
      this.$emit('editChannels', {channels: this.selectedChannels, phaseType: this.phase.phaseType});
    },
    updateTrigger() {
      this.$emit('editContent', {data: this.phase.phaseData.trigger, type: 'trigger', phaseType: this.phase.phaseType});
    }
  }
}
</script>

<style scoped>
.stdc-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stdc-box-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
}

.stdc-box-header-rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #17252A;
}

.stdc-box-header-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.stdc-box-header-title-ico {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: #D1D4D4;
}

.stdc-box-header-title-value {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  color: #17252A;
}

.stdc-box-body {
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 2px 1px #0000000D;
  background: #FFFFFF;
  border-radius: 10px;
  min-height: 78px;
  max-height: 220px;
}

.stdc-box-body-line {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #D1D4D4;
  width: 6px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.stdc-box-body .wysiwyg {
  max-height: 150px;
}

.stdc-box-body-col {
  padding: 13px 2px 13px 13px;
  width: 100%;
  border-right: 1px solid #D1D4D4;
}

.stdc-box-body-col-body {

}

.stdc-box-body-col-body-channels {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.stdc-box-body-col--padding {
  padding-left: 20px;
}

.stdc-box-body-col:last-child {
  border: none;
}

.stdc-box-body-col-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #17252A;
}

.stdc-box-body-col-header button {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
</style>
