import { render, staticRenderFns } from "./LeanCanvasCard.vue?vue&type=template&id=3ed6c725&scoped=true&"
import script from "./LeanCanvasCard.vue?vue&type=script&lang=js&"
export * from "./LeanCanvasCard.vue?vue&type=script&lang=js&"
import style0 from "./LeanCanvasCard.vue?vue&type=style&index=0&id=3ed6c725&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed6c725",
  null
  
)

export default component.exports