<template>
  <ContentWrapper>
    <div class="wrapper">
      <div class="short-container">
        <h1>{{ company.name }} <IconStar /></h1>
        <div class="company-billing-date">
          <p>
            {{ $t('6_3_company_active') }}
            <strong>
              {{ $t('6_3_company_active_until') }} {{ company.billingDate | moment('D. M. YYYY') }}
            </strong>
            <span>|</span>
            <MailToSupport
              :title="$t('6_3_contact_us')"
              :subject="$t('6_3_company_billing_info')"
              :body="mailToBody"
            />
          </p>
        </div>

        <div class="boxed">
          <div class="box">

            <div class="row">
              <div class="col">
                <FormGroup
                  :label="$t('9_1_label_name')"
                  :required="true"
                  :validationError="validationError.name"
                >
                  <input
                    type="text"
                    :placeholder="$t('9_1_placeholder_name')"
                    v-model="company.name"
                    @input="validationError.name = false"
                    ref="contentName"
                  />
                </FormGroup>
              </div>
              <div class="col">

                <div v-if="role !== 'READ_ONLY'">
                  <CompanyLogo :title="$t('6_3_image_default_name')" />
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_industry')"
                  :placeholder="$t('15_1_placeholder_industry')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
              </div>
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_business_type')"
                  :placeholder="$t('15_1_placeholder_business_type')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_annual_revenue')"
                  :placeholder="$t('15_1_placeholder_annual_revenue')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
              </div>
              <div class="col">
                <Dropdown
                  :label="$t('15_1_label_number_of_employees')"
                  :placeholder="$t('15_1_placeholder_number_of_employees')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
              </div>
            </div>

            <FormGroup :label="$t('15_1_label_main_company_website')" :required="false">
              <Resources
                :items="companyWebsites"
                :disabled="role === 'READ_ONLY'"
                :showError="companyWebsitesIsOpenShowError"
                transCreateNew="15_1_main_company_website_add"
                @updateResources="updateCompanyWebsites"
                @opened="companyWebsitesIsOpen = true"
                @closed="
                        ;(companyWebsitesIsOpen = false),
                          (companyWebsitesIsOpenShowError = false)
                      "
              />
            </FormGroup>

            <div>
              <FormGroup
                :label="$t('15_1_label_description')"
                :required="true"
                :validationError="validationError.description"
              >
              <textarea
                class="form-group__input large"
                :placeholder="$t('15_1_placeholder_description')"
                v-model="company.description"
                @input="validationError.description = false"
                ref="contentName"
              ></textarea>
              </FormGroup>
            </div>

            <div class="column">
              <Dropdown
                :label="$t('15_1_label_timezone')"
                :placeholder="$t('15_1_placeholder_timezone')"
                :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
              />
              <div class="short-column">
                <Dropdown
                  :label="$t('15_1_label_currency')"
                  :placeholder="$t('15_1_placeholder_currency')"
                  :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
                />
              </div>
            </div>

            <Accordion
              :title="$t('15_1_accordion_title_additional_info')"
              :description="$t('15_1_accordion_description_additional_info')"
            >
              <Dropdown
                :label="$t('15_1_label_project_type')"
                :placeholder="$t('15_1_placeholder_project_type')"
                :items="[{name: 'jedna', value: 'jedna'}, {name: 'dva', value: 'dva'}]"
              />

              <FormGroup
                :label="$t('9_1_label_name')"
              >

                <ol v-if="contacts.length">
                  <li v-for="(item, index) in contacts" :key="index">
                    <div>
                      <input type="text" v-model="item.name">
                      <textarea
                        :placeholder="$t('9_1_placeholder_name')"
                        v-model="item.description"
                      ></textarea>

                      <div>
                        <div><IconPhone /></div>
                        <input type="text" v-model="item.phone">
                      </div>

                      <div>
                        <div><IconMail /></div>
                        <input type="text"  v-model="item.email">
                      </div>

                      <div class="sources-contacts-buttons">
                        <Button type="primary" size="small">Save</Button>
                        <Button type="secondary" size="small">Cancel</Button>
                        <Button
                          type="secondary"
                          size="slim"
                          @click="handleRemoveContact(index)"
                        ><IcoBin /></Button>
                      </div>
                    </div>

                  </li>
                </ol>

                <ol v-if="!contacts.length">
                  <li>
                    <Button size="medium" @click="handleAddContact">Add contact</Button>
                  </li>
                </ol>

              </FormGroup>

              <FormGroup :label="$t('15_1_label_tools')" :required="false">
                <Resources
                  :items="tools"
                  :disabled="role === 'READ_ONLY'"
                  :showError="toolsIsOpenShowError"
                  transCreateNew="15_1_tools_add"
                  @updateResources="updateTools"
                  @opened="toolsIsOpen = true"
                  @closed="
                        ;(toolsIsOpen = false),
                          (toolsIsOpenShowError = false)
                      "
                />
              </FormGroup>

            </Accordion>
          </div>
          <div class="box">
            <Button type="primary">{{ $t('15_3_2_btn_save_changes') }}</Button>
          </div>
        </div>

      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/button/Button.vue";
import IcoBin from "@/components/svg/medium/IcoBin.vue";
import IcoBinSmall from "@/components/svg/IcoBinSmall.vue";
import IconPhone from "@/components/sources/icons/IconPhone.vue";
import IconMail from "@/components/sources/icons/IconMail.vue";
import {obj_clone} from "@/services/helpers/clone";
import textLength from "@/constants/TextLengthConstant";
import {DateService} from "@/services/DateService";
import COMPANY_INFO from "@/graphql/CompanyInfo.gql";
import Dropdown from "@/components/dropdown/Dropdown.vue";
import Resources from "@/components/design/Resources.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import IconStar from "@/components/sources/icons/IconStar.vue";
import MailToSupport from "@/components/MailToSupport.vue";
import ContentWrapper from "@/components/sources/ContentWrapper.vue";

export default {
  components: {
    ContentWrapper,
    MailToSupport,
    IconStar,
    CompanyLogo,
    Accordion,
    Resources,
    Dropdown,
    IconMail,
    IconPhone,
    IcoBinSmall,
    IcoBin,
    Button,
    FormGroup

  },
  apollo: {
    companies: {
      query: COMPANY_INFO,
      variables() {
        return {
          id: this.companyID,
        }
      },
      skip() {
        return !this.companyID
      },
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    length() {
      return textLength
    },
    timezones() {
      return DateService.getListOfAvailableTimezones()
    },
    company() {
      if (this.companies.length > 0) {
        this.name = this.companies[0].name
        this.description = this.companies[0].description
        this.timezone = this.companies[0].zoneId
        this.billingOverdue = this.companies[0].billingOverdue

        return this.companies[0]
      }
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    companyID() {
      if (!this.$route.params.id) {
        if (this.selectedCompany) {
          return this.selectedCompany.id
        }
      } else {
        return this.$route.params.id
      }
    },
    mailToBody() {
      let _content = ''
      const content_cs =
        'Společnost: {companyName}, {companyId} \nZašlete mi fakturu na předplatné: Sólo Dino / Triasový Tým / Jurská Smečka / Obsahový T-Rex \nMám zájem o období: ROČNÍ / MĚSÍČNÍ \n\nSlevový kód:\nFakturační údaje: \n- Název firmy \n- Sídlo (ulice, město, psč, země) \n- IČO \n- DIČ'
      const content_en = `Company: {companyName}, {companyId} \nSend me an invoice for subscription: Solo Dino / Triassic Team / Jurassic Crew / Content T-Rex \nI am interested in this period: YEARLY / MONTHLY \n\nPromo code: \nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)`

      _content = content_cs

      if (this.$i18n.locale === 'en') {
        _content = content_en
      }

      _content = _content
        .replace('{companyName}', this.selectedCompany.name)
        .replace('{companyId}', this.selectedCompany.id)

      return encodeURIComponent(_content)
    },
  },
  data() {
    return {
      validationError: {
        name: false,
      },
      contacts: [],

      tools: [],
      toolsIsOpen: false,
      toolsIsOpenShowError: false,

      companyWebsites: [],
      companyWebsitesIsOpen: false,
      companyWebsitesIsOpenShowError: false,

      billingOverdue: false,
      prevHeight: 0,
      availableHeight: '344px',
      focusName: false,
      focusDescription: false,
      focusTimezone: false,

      companies: [],
      invites: [],
      showModalDeleteAccount: false,
      showModalForgotPassword: false,
      showModalDeleteCompany: false,
      disabledEditCompany: true,
      disabledInviteTeamMembers: true,
      errorEditCompany: [],
      name: '',
      description: '',
      timezone: 'Europe/Prague',
      searchTeam: '',
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        },
      },
      invalidName: false,
      invalidDescription: false,
      invalidTimezone: false,
      userTab: 'members',
    }
  },
  methods: {
    initPage() {
      this.$apollo.queries.companies.refetch()
    },
    handleAddContact() {
      this.contacts.push( obj_clone(this.contact_template) )
    },
    handleRemoveContact(index) {
      this.contacts.splice(index, 1);
    },
    updateCompanyWebsites(sites) {
      this.companyWebsites = sites;
    },
    updateTools() {

    }
  },
  watch: {
    name() {
      this.disabledEditCompany = this.name === this.company.name
    },
    description() {
      this.disabledEditCompany = this.description === this.company.description
    },
    company() {
      if (this.company.id !== this.selectedCompany.id) {
        this.$store.commit('setCompany', this.company)
      }
    },
    '$route.params': 'initPage',
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.short-container {
  width: 768px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.sources-contacts-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.company-billing-date {
  color: #17252A;
  text-align: center;
}

.company-billing-date p{
  color: #17252A;
}

.company-billing-date p span {
  color: #E7E9E9;
}
</style>
